<script setup>
import AddRssFeed from '@/gql/users/AddRssFeed.gql'
import RemoveRssFeed from '@/gql/users/RemoveRssFeed.gql'
import ListRssFeeds from '@/gql/users/ListRssFeeds.gql'
import GetUser from '@/gql/users/GetUser.gql'
import { ref, watch } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object
})

const category = ref('')
const feedUrl = ref('')
const feeds = ref(null)

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { result: rssFeeds, loading, error: rssFeedsError, refetch } = useQuery(ListRssFeeds, { userId: props.user.id })

const {
  mutate: addFeed,
  onDone: addFeedDone,
  error: addFeedError
} = useMutation(AddRssFeed, () => ({
  update: (cache, { data }) => {}
}))
const {
  mutate: removeFeed,
  onDone: removeFeedDone,
  error: removeFeedError
} = useMutation(RemoveRssFeed, () => ({
  update: (cache, { data }) => {}
}))

const handleAddFeed = async () => {
  if (category.value !== '' && feedUrl.value !== '') {
    try {
      addFeed({ userId: props.user.id, feedUrl: feedUrl.value, category: category.value })
    } catch (err) {
      alert(err)
    }
  } else {
    alert('Missing required parameters!')
  }
}

const handleRemoveFeed = async (feed) => {
  if (confirm('Are you sure you wish to remove this feed?')) {
    try {
      await removeFeed({ id: feed.id })
    } catch (err) {
      alert(err)
    }
    try {
      const adminID = auth.getUserId()
      await createAdminAction({ userId: adminID, actionId: 20, target: feed.feed_url })
    } catch (error) {}
  }
}

addFeedDone(async (res) => {
  const adminID = auth.getUserId()
  try {
    await createAdminAction({ userId: adminID, actionId: 19, target: feedUrl.value })
    feedUrl.value = ''
    category.value = ''
  } catch (error) {
    alert(error)
  }
  refetch()
})
removeFeedDone(() => {
  refetch()
})

watch(rssFeeds, (value) => {
  console.log('RSS FEEDS', value.feeds)
  if (value?.feeds) {
    feeds.value = value.feeds
  }
})
watch(rssFeedsError, (value) => {
  alert(value)
})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>RSS Feeds</h3>
    </div>
    <div class="card-body items-center text-center">
      <div class="card-actions w-full">
        <div class="mb-4 border p-2 w-full" v-if="feeds" v-for="feed in feeds">
          <table class="table table-compact w-full">
            <tr>
              <td class="py-0"><p class="font-bold m-0">Feed Url</p></td>
              <td class="py-0">{{ feed.feed_url }}</td>
            </tr>
            <tr>
              <td class="py-0"><p class="font-bold m-0">Category</p></td>
              <td class="py-0">{{ feed.category }}</td>
            </tr>
          </table>
          <button class="btn btn-sm btn-primary mt-4 rounded-sm" @click="handleRemoveFeed({ id: feed.id, feed_url: feed.feed_url })">Remove Feed</button>
        </div>
        <label class="btn btn-primary w-full rounded-sm" for="feed-modal">Add Feed</label>
        <input type="checkbox" id="feed-modal" class="modal-toggle" />
        <!-- RSS Feed Modal -->
        <div class="modal">
          <div class="modal-box flex flex-col justify-center items-center prose prose-slate rounded-sm">
            <h3 class="font-bold text-lg flex justify-center">Add Feed</h3>
            <div class="flex justify-between w-5/6 mb-4">
              <label for="url-input"><p class="m-0 flex justify-start">Feed Url</p></label>
              <input type="text" name="url-input" placeholder="Input RSS URL" class="max-w-xs bg-blue-100 border border-slate-400 px-4 w-3/4 input-sm rounded-sm" v-model="feedUrl" />
            </div>
            <div class="flex justify-start w-5/6">
              <p class="m-0 flex justify-start">Category</p>
              <select class="select select-primary select-sm w-3/4 max-w-xs rounded-sm" v-model="category">
                <option selected disabled>Set priority</option>
                <option value="US">Politics</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Technology">Technology</option>
                <option value="Fashion">Fashion</option>
                <option value="Parents">Family</option>
                <option value="Health">Health</option>
                <option value="Sports">Sports</option>
                <option value="Business">Business</option>
                <option value="Finance">Finance</option>
                <option value="Global">World</option>
              </select>
            </div>
            <div class="modal-action flex justify-around w-5/6">
              <label for="feed-modal" class="btn w-24" @click="handleAddFeed">Add</label>
              <label for="feed-modal" class="btn w-24" @click="handleCancel">Cancel</label>
            </div>
          </div>
        </div>
        <!-- end RSS Feed modal -->
      </div>
    </div>
  </div>
</template>
