import { createRouter, createWebHistory } from 'vue-router'

import $auth from '@/services/auth.js'

// import views
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard.vue'
import FindUsers from '@/views/UsersMenu/FindUsers.vue'
import SuspendedUsers from '@/views/UsersMenu/SuspendedUsers.vue'
import VerifyUsers from '@/views/UsersMenu/VerifyUsers.vue'
import RecentUsers from '@/views/UsersMenu/RecentUsers.vue'
import BlockedDomains from '@/views/DomainsMenu/BlockedDomains.vue'
import BlockedEmailDomains from '@/views/DomainsMenu/BlockedEmailDomains.vue'
import BlockedWords from '@/views/DomainsMenu/BlockedWords.vue'
import ReportedUsers from '@/views/ReportsMenu/ReportedUsers.vue'
import ReportedPosts from '@/views/ReportsMenu/ReportedPosts.vue'
import LiveChannels from '@/views/ChannelsMenu/LiveChannels.vue'
import Notifications from '@/views/Notifications.vue'
import AdminAuditLog from '@/views/AdminAuditLog.vue'
import AuditLog from '@/views/AuditLog.vue'
import Moderation from '@/views/Moderation.vue'
import UserDetail from '@/views/UsersMenu/UserDetail.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      children: [
        {
          path: '/',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/findusers',
          name: 'FindUsers',
          component: FindUsers
        },
        {
          path: '/suspendedusers',
          name: 'SuspendedUsers',
          component: SuspendedUsers
        },
        {
          path: '/verifyusers',
          name: 'VerifyUsers',
          component: VerifyUsers
        },
        {
          path: '/recentusers',
          name: 'RecentUsers',
          component: RecentUsers
        },
        {
          path: '/blockeddomains',
          name: 'BlockedDomains',
          component: BlockedDomains
        },
        {
          path: '/blockedemaildomains',
          name: 'BlockedEmailDomains',
          component: BlockedEmailDomains
        },
        {
          path: '/blockedwords',
          name: 'BlockedWords',
          component: BlockedWords
        },
        {
          path: '/reportedusers',
          name: 'ReportedUsers',
          component: ReportedUsers
        },
        {
          path: '/reportedposts',
          name: 'ReportedPosts',
          component: ReportedPosts
        },
        {
          path: '/livechannels',
          name: 'LiveChannels',
          component: LiveChannels
        },
        {
          path: '/notifications',
          name: 'Notifications',
          component: Notifications
        },
        {
          path: '/auditlog',
          name: 'AuditLog',
          component: AuditLog
        },
        {
          path: '/moderation',
          name: 'Moderation',
          component: Moderation
        },
        {
          path: '/user/:id',
          name: 'UserDetail',
          component: UserDetail
        },
        {
          path: '/auditlog/:id/:username',
          name: 'AdminAuditLog',
          component: AdminAuditLog
        }
      ]
    }
    // {
    //     path: ,
    //     name: ,
    //     component: () => import() //view in here
    // }
  ]
})

router.beforeEach(async (to, from, next) => {
  const isLoggedin = await $auth.isLoggedIn()
  if (isLoggedin && to.name === 'Login') {
    // Logged in but trying to access an onboarding screen
    next({ name: 'Dashboard' })
  } else if (!isLoggedin && to.name !== 'Login') {
    // Logged out but trying to access an app screen
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
