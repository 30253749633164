<script setup>
import { ref, computed, watch, onBeforeMount } from 'vue'
import EditLimitsModal from '../modals/EditLimitsModal.vue'
import { useMutation } from '@vue/apollo-composable'
import UpdateUserParams from '@/gql/users/UpdateUserParams.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object,
  refetchUser: Function
})

onBeforeMount(() => {
  if (props.user?.userParams) {
    userParams.value.messageCharacters = props.user.userParams.messageCharacters
    userParams.value.quickVideoDuration = props.user.userParams.quickVideoDuration
    userParams.value.galleryVideoDuration = props.user.userParams.galleryVideoDuration
    userParams.value.postCharacters = props.user.userParams.postCharacters
  }
})

const userParams = ref({})

// const userLimits = computed(() => {
//   return {...props.user.userParams}
// })

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: updateLimits, error: updateError, onDone: updateLimitsDone } = useMutation(UpdateUserParams)

const handleUpdate = () => {
  try {
    updateLimits({ id: props.user.id, userParams: userParams.value })
  } catch (err) {
    alert(err)
  }
}

const handleCancel = () => {
  if (props?.user) {
    userParams.value.messageCharacters = props.user.userParams.messageCharacters
    userParams.value.quickVideoDuration = props.user.userParams.quickVideoDuration
    userParams.value.galleryVideoDuration = props.user.userParams.galleryVideoDuration
    userParams.value.postCharacters = props.user.userParams.postCharacters
  }
}

watch(updateError, (value) => {
  alert(value)
})

updateLimitsDone(() => {
  const adminID = auth.getUserId()
  try {
    createAdminAction({ userId: adminID, actionId: 22, target: props.user.id })
  } catch (error) {
    alert(error)
  }
  props.refetchUser()
})
</script>

<template>
  <!-- Profile CARD -->
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="profile-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>User Limits</h3>
    </div>
    <div class="card-body items-center text-center">
      <table class="table table-compact w-full" v-if="props.user">
        <tr>
          <td><p class="font-bold">Message Characters</p></td>
          <td>{{ userParams.messageCharacters }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Forum Video Duration</p></td>
          <td>{{ userParams.quickVideoDuration }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Gallery Video Duration</p></td>
          <td>{{ userParams.galleryVideoDuration }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Post Characters</p></td>
          <td>{{ userParams.postCharacters }}</td>
        </tr>
      </table>
      <div class="card-actions w-full">
        <label class="btn btn-primary w-full rounded-sm" for="edit-limits-modal">Edit Limits</label>
        <input type="checkbox" class="modal-toggle" id="edit-limits-modal" />
        <EditLimitsModal
          v-model:messageCharLimit="userParams.messageCharacters"
          v-model:forumDuration="userParams.quickVideoDuration"
          v-model:galleryDuration="userParams.galleryVideoDuration"
          v-model:postCharLimit="userParams.postCharacters"
          :updateLimits="handleUpdate"
          :handleCancel="handleCancel"
        />
      </div>
    </div>
  </div>
  <!-- End Profile Card-->
</template>
