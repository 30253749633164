query GetAuditLog($userId:String){
  log: admin_get_audit_log(userId:$userId){
    id,
    user_id,
    created_at,
    action_id,
    action,
    target,
    notes
  }
}