fragment BasicNotification on Notification {
  id
  body
  typeNotification
  status
  url
  createdAt
  updatedAt
}

mutation CreateNotification($status:String!,$typeNotification:String!,$body:String! $url:String){
  create_notification(
    status:$status,
    typeNotification:$typeNotification,
    body:$body,
    url:$url,
   ) {
   ...BasicNotification
  }
}
