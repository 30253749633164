query GetUser($id:String!){
  user: admin_get_user(id:$id){
    id
    firstname
    lastname
    username
    displayname
    followerCount
    followingCount
    friendCount
    bio
    postCount
    location
    privateLocation
    website
    profilePhotoURL
    bannerImageURL
    gender
    dob
    email
    phoneNo
    userParams
    suspended
    liveStreamKey
    channelNumber
    channelTrueNews
    createdAt
    badgeType
    suspension {
      admin {
        id
        displayname
      }
      createdAt
      reason
    }
    priorityScore
    channelPriorityScore
    enablePayments
    paymentName
    paymentAddress
  }
}
