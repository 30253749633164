Need to see some user complaints to complete this card

<script setup>
import ClearUserReports from '@/gql/reports/ClearUserReports.gql'
import ListUserComplaints from '@/gql/users/ListUserComplaints.gql'
import { useQuery } from '@vue/apollo-composable'
import { ref, watch } from 'vue'

const props = defineProps({
  user: Object
})

const userComplaints = ref(null)

const { result: complaints, loading, error, refetch } = useQuery(ListUserComplaints, { userId: props.user.id })

watch(complaints, (value) => {
  console.log('COMPLAINTS', value.complaints)
  userComplaints.value = value.complaints
})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>User Complaints</h3>
    </div>
    <div class="card-body items-center text-center">
      <div>Enter a V FOR for user complaints here {{ user.id }}</div>
      <div class="card-actions w-full">
        <button class="btn btn-primary w-full rounded-sm">Clear User Complaints</button>
      </div>
    </div>
  </div>
</template>
