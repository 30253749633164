Need recently joined users to finish component.

<script setup>
import ListRecentUsers from "@/gql/users/ListRecentUsers.gql";
import { useQuery } from "@vue/apollo-composable";
import { ref, watch } from "vue";
import { formatDate } from '@/services/utilities.js'

const users = ref([])

const { result: recentUsers, loading, error, refetch } = useQuery(ListRecentUsers);

watch(recentUsers, (value) => {
  console.log('RECENT USERS', value)
  if (value?.users) {
    users.value = value.users;
  }
});

</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Recently Joined</h2>
      </div>
      <div v-if="users.length === 0">There are no recently joined users...</div>
      <div class="mt-10" v-if="users.length > 0">
        <div class="overflow-x-auto">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Created</th>
                <th>Email</th>
                <th>Displayname</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th class="rounded-sm">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users">
                <th></th>
                <td>{{ user.username }}</td>
                <td>{{ formatDate(user.createdAt) }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.displayname }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td>{{ user.suspended || 'Active' }}</td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Displayname</th>
                <th>Badge Request</th>
                <th>Badge Type</th>
                <th class="rounded-sm">Actions</th>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
