query {
  users: admin_list_channels{
    id
    username
    channelName
    channelNumber
    channelTrueNews
    liveStreamKey
  }
}
