<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import CustomFilterInput from '@/components/CustomFilterInput.vue'
import ListRestrictedWords from '@/gql/restrictedWords/ListRestrictedWords.gql'
import InsertRestrictedWord from '@/gql/restrictedWords/InsertRestrictedWord.gql'
import DeleteRestrictedWord from '@/gql/restrictedWords/DeleteRestrictedWord.gql'
import { useQuery, useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const searchText = ref('')
const blockWord = ref('')
const wordScore = ref(0)
const urlArray = ref([])

const filteredArray = computed(() => {
  return urlArray.value.filter((word) => {
    return word.word.toLowerCase().indexOf(searchText.value.toLowerCase()) != -1
  })
})

const { result, loading, error, refetch } = useQuery(ListRestrictedWords)

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: deleteItem, onDone: deleteDone } = useMutation(DeleteRestrictedWord, () => ({
  update: (cache, { data }) => {
    console.log('DATA', data)
  }
}))

const { mutate: insertItem, onDone: insertDone } = useMutation(InsertRestrictedWord, () => ({
  update: (cache, { data }) => {
    console.log('DATA', data)
  }
}))

const restrictWord = async (event) => {
  if (blockWord.value !== '' && wordScore.value !== 0) {
    if (wordScore.value > 0 && wordScore.value < 100) {
      const payload = { word: blockWord.value, score: Math.round(parseInt(wordScore.value)) }
      try {
        await insertItem(payload)
        blockWord.value = ''
        wordScore.value = 0
      } catch (e) {
        error.log(e)
      }
    } else {
      alert('Score must be a number between 0 and 100!')
      event.preventDefault()
    }
  } else {
    alert('You must add a word to block and give it a score.')
  }
}

deleteDone((res) => refetch())
insertDone((res) => {
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 14, target: res.data.admin_insert_restricted_word.word })
  refetch()
})

onMounted(() => {
  refetch()
})

watch(result, (value) => {
  if (value?.words) {
    urlArray.value = value.words
  }
})
</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex flex-wrap justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0 w-96">Blocked Words</h2>
        <form v-on:submit.prevent="onSubmit" class="w-1/2 flex md:justify-end sm:justify-start">
          <CustomFilterInput v-model="searchText" class="w-[100%] rounded-sm" />
        </form>
        <label class="btn btn-primary rounded-sm min-h-[2rem] h-[2.3rem]" for="my-modal">Block New Word</label>
        <input type="checkbox" id="my-modal" class="modal-toggle" />
        <!-- Block word Modal -->
        <div class="modal">
          <div class="modal-box flex flex-col justify-center items-center">
            <h3 class="font-bold text-lg flex justify-center">Which word would you like to block?</h3>
            <input v-model="blockWord" type="text" name="blockWord" placeholder="Insert word to block..." class="bg-blue-100 border-slate-400 h-9 px-4 rounded-sm mb-6 mt-12" />
            <input v-model="wordScore" min="0" max="100" type="number" name="wordScore" placeholder="Insert word score..." class="bg-blue-100 border-slate-400 h-9 px-4 rounded-sm mb-6 w-1/4" />
            <!-- <p class="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p> -->
            <div class="modal-action flex justify-around">
              <label for="my-modal" class="btn" @click="restrictWord">Block</label>
              <label for="my-modal" class="btn">Cancel</label>
            </div>
          </div>
        </div>
        <!-- end Block Word modal -->
      </div>
      <div v-if="loading"><p>Loading blocked words...</p></div>
      <div class="overflow-x-auto mt-12" v-if="!loading">
        <table class="table border border-slate-300 w-full">
          <thead>
            <tr>
              <th class="rounded-sm"></th>
              <th>ID</th>
              <th>Word</th>
              <th>Score</th>
              <th class="rounded-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover" v-for="word in filteredArray">
              <th></th>
              <td>{{ word.id }}</td>
              <td>{{ word.word }}</td>
              <td>{{ word.score }}</td>
              <td><button class="btn btn-primary btn-sm" @click="deleteItem({ id: word.id })">Unblock</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
