query ListUserModerationHistory($userId:String!) {
  posts: admin_list_moderation_posts_for_user(userId:$userId){
    id
    type
    bodyText
    postedByUser {
      id
      username
    }
    images
    video
    profanityStatus
    createdAt
    profanityAdmin {
      id
      username
    }
  }
}