<script setup>
import { useQuery } from '@vue/apollo-composable'
import { formatDate } from '@/services/utilities.js'
import { ref, watch, onMounted } from 'vue'
import GetAuditLog from '@/gql/users/GetAuditLog.gql'

const auditLog = ref(null)


const {result: auditRes, error: auditError, refetch} = useQuery(GetAuditLog)

onMounted(() => {
  refetch()
})

watch(auditRes, (value) => {
  console.log('auditRes', value)
  if (value?.log) {
    auditLog.value = value.log
  }
})



</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Notifications</h2>
      </div>
      <div class="overflow-x-auto mt-12">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="rounded-sm"></th>
              <th>Date</th>
              <th>Admin</th>
              <th>Action</th>
              <th>Target</th>
              <th class="rounded-sm">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover" v-for="log in auditLog">
              <th class="rounded-sm"></th>
              <td>{{ formatDate(log.created_at) }}</td>
              <td>{{ log.user_id }}</td>
              <!-- need help here figuring out how to get the user info based on ID possibly in the same query? -->
              <td>{{ log.action }}</td>
              <td>{{ log.target }}</td>
              <td class="rounded-sm">
                {{ log.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
