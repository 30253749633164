import { createApp, h, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
//import { createRouter } from "vue-router";
import * as Sentry from "@sentry/vue";

import '@/style.css'
import App from './App.vue'
import apolloClient from './services/apollo'
import router from './router'

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
});

Sentry.init({
  app,
  dsn: "https://c75001cfeb4349ec90dca4b99f23e033@o4505397740437504.ingest.sentry.io/4505404541042688",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router);
//app.use(Sentry.createTracingMixins({
//  traceRequest: true,
//}));
app.mount('#app');