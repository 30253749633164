<script setup>
import EnableLiveStreaming from '@/gql/users/EnableLiveStreaming.gql'
import RevokeLiveStreaming from '@/gql/users/RevokeLiveStreaming.gql'
import UpdateChannelTrueNews from '@/gql/users/UpdateChannelTrueNews.gql'
import { useMutation } from '@vue/apollo-composable'
import { watch } from 'vue'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object
})

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: toggleTrueNews, onDone: toggleNewsDone, error: trueNewsError } = useMutation(UpdateChannelTrueNews)
const { mutate: enableLiveStream, onDone: enableDone, error: enableError } = useMutation(EnableLiveStreaming)
const { mutate: revokeLiveStream, onDone: revokeDone, error: revokeError } = useMutation(RevokeLiveStreaming)

toggleNewsDone((res) => {
  // console.log('RESULT', res.data.admin_update_user.channelTrueNews)
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 29, target: props.user.id, adminNotes: res.data.admin_update_user.channelTrueNews })
})
enableDone((res) => {
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 5, target: props.user.id })
})
revokeDone((res) => {
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 6, target: props.user.id })
})

watch(trueNewsError, (value) => {
  if (value) {
    alert(value)
  }
})
watch(enableError, (value) => {
  if (value) {
    alert(value)
  }
})
watch(revokeError, (value) => {
  if (value) {
    alert(value)
  }
})
watch(adminActionError, (value) => {
  if (value) {
    alert(value)
  }
})
</script>
<template>
  <!-- Channel CARD -->
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Channel</h3>
    </div>
    <div class="card-body items-center text-center">
      <table class="table table-compact w-full">
        <tr>
          <td><p class="font-bold">True News</p></td>
          <td><input type="checkbox" :checked="props.user.channelTrueNews" @change.prevent="toggleTrueNews({ id: props.user.id, channelTrueNews: $event.target.checked })" /></td>
        </tr>
        <tr>
          <td><p class="font-bold">Url</p></td>
          <td>rtmp://rtmp.clouthub.com:5222/app</td>
        </tr>
        <tr>
          <td><p class="font-bold">Key</p></td>
          <td>
            <p class="break-all whitespace-normal">{{ props.user.liveStreamKey }}</p>
          </td>
        </tr>
      </table>
      <div class="card-actions w-full">
        <button class="btn btn-error w-full rounded-sm" v-if="props.user.liveStreamKey" @click="revokeLiveStream({ userId: props.user.id })">Revoke Live Streaming</button>
        <button class="btn btn-success w-full rounded-sm" v-if="!props.user.liveStreamKey" @click="enableLiveStream({ userId: props.user.id })">Enable Live Streaming</button>
      </div>
    </div>
  </div>
  <!-- End Channel Card -->
</template>
