query ListUserComplaints ($userId:String!) {
  complaints: admin_list_user_complaints(userId:$userId){
    id
    user{
      id
      displayname
    }
    reason
    details
    createdAt
    cleared
  }
}