<script setup>
import ListUserSessions from '@/gql/users/ListUserSessions.gql'
import { useQuery } from '@vue/apollo-composable'
import { ref, watch, toRef, onMounted } from 'vue'

const props = defineProps({
  user: Object
})

const sessions = ref(null)

const { result, loading, error, refetch } = useQuery(ListUserSessions, { userId: props.user.id })

watch(result, (value) => {
  console.log('SESSIONS', value)
  let results = value.sessions
  if (value.sessions) {
    console.log('Yes! value')
    if (value.sessions.length > 6) {
      console.log('greater than six')
      results = value.sessions.slice(0, 6)
    }
  }
  console.log('RESULTS', results)
  sessions.value = results
})

onMounted(() => {
  console.log('USER', props.user)
})

function getTimeSince(dateString) {
  const date = new Date(dateString)
  const now = new Date()
  const diffMs = now - date
  const diffSec = Math.round(diffMs / 1000)
  const diffMin = Math.round(diffSec / 60)
  const diffHr = Math.round(diffMin / 60)
  const diffDay = Math.round(diffHr / 24)

  if (diffDay > 0) {
    return `${diffDay} days ago`
  } else if (diffHr > 0) {
    return `${diffHr} hours ago`
  } else if (diffMin > 0) {
    return `${diffMin} minutes ago`
  } else if (diffSec > 0) {
    return `${diffSec} seconds ago`
  } else {
    return 'NOW'
  }
}
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>User Sessions</h3>
    </div>
    <div class="card-body items-center text-center prose prose-slate">
      <h3 class="font-bold">Six most recent sessions</h3>
      <div class="mb-4 border px-2 w-full" v-if="sessions" v-for="session in sessions">
        <table class="table table-compact w-full">
          <tr>
            <td class="py-0"><p class="font-bold m-0">Time Since</p></td>
            <td class="py-0">{{ getTimeSince(session.createdAt) }}</td>
          </tr>
          <tr>
            <td class="py-0"><p class="font-bold m-0">App version</p></td>
            <td class="py-0">{{ session.appVersion }}</td>
          </tr>
          <tr>
            <td class="py-0"><p class="font-bold m-0">OS</p></td>
            <td class="py-0">{{ session.os }}</td>
          </tr>
          <tr>
            <td class="py-0"><p class="font-bold m-0">Device</p></td>
            <td class="py-0">{{ session.device }}</td>
          </tr>
          <tr>
            <td class="py-0"><p class="font-bold m-0">Browser</p></td>
            <td class="py-0">
              <p class="m-0 whitespace-normal break-all">{{ Object.keys(session.browser).filter((key) => session.browser[key] === true) }}</p>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div class="card-actions w-full">
            <button class="btn btn-primary w-full rounded-sm">Verify</button>
          </div> -->
    </div>
  </div>
</template>
