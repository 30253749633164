<script setup>
  import { useRouter } from 'vue-router';
  const router = useRouter()
  import auth from '@/services/auth.js'
  import MobileMenu from '@/components/MobileMenu.vue'
  import { ref } from 'vue'

  const dataTheme = ref(false)

  const toggleTheme = () => {
    console.log(dataTheme.value)
    if (dataTheme.value === false) {
      dataTheme.value = true
    } else {
      dataTheme.value = false
    }
  }

</script>

<template>
  <div id="page-container" class="h-full w-full flex flex-col overflow-auto" :data-theme="dataTheme === false ? 'light' : 'dark'">
    <div id="nav-container" class="w-full h-20 flex justify-center bg-slate-200">
      <div class="navbar bg-slate-200 sm:w-5/6 p-0">
        <div class="navbar-start" tabindex="0">
         <MobileMenu />
          <router-link to="/"><a class="btn btn-ghost normal-case text-xl hover:bg-slate-300/75 hover:cursor-pointer p-0 px-2 rounded-md w-44">CloutHub Admin</a></router-link>
        </div>
        <div class="navbar-center hidden lg:flex w-1/2 h-12">
          <div id="menu" class="w-full">
            <ul class="flex flex-row justify-around">
              <li class="px-2 hover:bg-slate-300 rounded ease-in duration-100 h-8 leading-8 dropdown dropdown-hover">
                <label tabindex="0">Users</label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 w-52">
                  <router-link to="/findusers"><li><a class="h-10">Find Users</a></li></router-link>
                  <router-link to="/suspendedusers">
                    <li><a class="h-10">Suspended Users</a></li>
                  </router-link>
                  <router-link to="/verifyusers">
                    <li><a class="h-10">Verify Users</a></li>
                  </router-link>
                  <router-link to="/recentusers">
                    <li><a class="h-10">Recently Joined</a></li>
                  </router-link>
                </ul>
              </li>
              <li class="px-2 hover:bg-slate-300 rounded ease-in duration-100 h-8 leading-8 dropdown dropdown-hover">
                <label tabindex="0">Blacklists</label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                  <router-link to="/blockeddomains">
                    <li><a class="h-10">Blocked Domains</a></li>
                  </router-link>
                  <router-link to="/blockedemaildomains">
                    <li><a class="h-10">Blocked Emails</a></li>
                  </router-link>
                  <router-link to="/blockedwords">
                    <li><a class="h-10">Blocked Words</a></li>
                  </router-link>
                </ul>
              </li>
              <li class="px-2 hover:bg-slate-300 rounded ease-in duration-100 h-8 leading-8 dropdown dropdown-hover">
                <label tabindex="0">Reports</label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                  <router-link to="/reportedusers">
                    <li><a class="h-10">Reported Users</a></li>
                  </router-link>
                  <router-link to="/reportedposts">
                    <li><a class="h-10">Reported Posts</a></li>
                  </router-link>
                </ul>
              </li>
              <li class="px-2 hover:bg-slate-300 rounded ease-in duration-100 h-8 leading-8 dropdown dropdown-hover">
                <label tabindex="0">Channels</label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                  <router-link to="/livechannels">
                    <li><a class="h-10">Live Channels</a></li>
                  </router-link>
                </ul>
              </li>
              <router-link to="/moderation">
                <li class="px-2 hover:bg-slate-300 rounded hover:cursor-pointer ease-in duration-100 h-8 leading-8">Moderation</li>
              </router-link>
              <router-link to="/notifications">
                <li class="px-2 hover:bg-slate-300 rounded hover:cursor-pointer ease-in duration-100 h-8 leading-8">Notifications</li>
              </router-link>
              <li class="px-2 hover:bg-slate-300 rounded ease-in duration-100 h-8 leading-8 dropdown dropdown-hover">
                <label tabindex="0">Meetings</label>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                  <li><a class="h-10">Meeting Earnings</a></li>
                  <li><a class="h-10">Meeting Livestreams</a></li>
                </ul>
              </li>
              <router-link to="/auditlog">
                <li class="px-2 hover:bg-slate-300 rounded hover:cursor-pointer ease-in duration-100 h-8 leading-8">AuditLog</li>
              </router-link>
            </ul>
          </div>
        </div>
        <div class="navbar-end">
          <div class="form-control mr-10">
  <label class="label cursor-pointer">
    <span class="label-text text-sm">Theme</span> 
    <input type="checkbox" class="toggle ml-3" v-model="dataTheme" @click="toggleTheme" />
  </label>
</div>
          <div class="dropdown dropdown-end">
            <label tabindex="0" class="btn btn-ghost btn-circle avatar">
              <div class="w-10 rounded-full">
                <img src="https://www.gravatar.com/avatar/00000000000000000000000000000000" onerror="this.onerror = null; this.src='https://www.gravatar.com/avatar/00000000000000000000000000000000" />
              </div>
            </label>
            <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
              <li>
                <a class="justify-between">
                  Profile
                  <span class="badge">New</span>
                </a>
              </li>
              <li><a>Settings</a></li>
              <li><a @click="auth.logout">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
