<script setup>
import { ref, watch, onMounted } from "vue";
import ListModerationPosts from "@/gql/moderation/ListModerationPosts.gql";
import SetModerationStatus from "@/gql/moderation/SetModerationStatus.gql";
import GraphqlHiveFlushQueue from '@/gql/moderation/GraphqlHiveFlushQueue.gql';
import { useQuery, useMutation } from "@vue/apollo-composable";

const posts = ref([]);
const rejectValue = ref("");
const currentPost = ref(null);

const { result, error, loading, refetch } = useQuery(ListModerationPosts);
const { mutate: approveAll, onDone: approveDone } = useMutation(GraphqlHiveFlushQueue);

const getPostAge = (dateString) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  const differenceMs = currentDate - date;
  const daysAge = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return `${daysAge} days ago`;
};

const setCurrentPost = (post) => {
  currentPost.value = post;
};

const previewPost = (post) => {
  // console.log('POST', post)
  window.open(`${import.meta.env.VITE_APP_URL}/#/posts/${post.id}`);
};

const { mutate: setStatus, onDone: statusComplete } = useMutation(SetModerationStatus, () => ({
  update: (cache, { data }) => {
    console.log("DATA", data);
  },
}));

const rejectPost = async (event) => {
  // console.log('POST', currentPost.value, 'STATUS', status, 'REASON', rejectValue.value)
  try {
    await setStatus({ id: currentPost.value.id, status: "rejected", reason: rejectValue.value });
  } catch (e) {
    event.preventDefault()
    alert(e);
  }
}

const approvePost = async (post) => {
  try {
    await setStatus({id: post.id, status: 'completed', reason: null})
  } catch (e) {
    alert(e)
  }
}

statusComplete(() => {
  refetch();
});

watch(result, (value) => {
  console.log("VALUE", value);
  posts.value = value.posts;
})

approveDone(() => {
  refetch();
})

onMounted(() => {
  refetch()
})
</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Moderation Queue</h2>
        <button class="btn btn-primary rounded-sm" @click="approveAll">Approve All</button>
      </div>
      <div class="mt-10">
        <div v-if="loading"><p>Loading Queue....</p></div>
        <div class="overflow-x-auto" v-if="!loading">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Post</th>
                <th>Media</th>
                <th class="rounded-sm">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="post in posts">
                <th></th>
                <td class="whitespace-normal prose prose-slate">
                  <div>{{ post.postedByUser.username }}</div>
                  <div>{{ getPostAge(post.createdAt) }}</div>
                  <div>
                    <p class="break-words">{{ post.bodyText }}</p>
                  </div>
                </td>
                <td>
                  <div v-if="post.images" v-for="image in post.images"><img :src="image.url" class="w-64" /></div>
                  <div v-if="post.video">
                    <video :src="post.video.url" class="h-2"></video>
                    <img src="../assets/video_error.png" class="w-64" />
                  </div>
                </td>
                <td class="prose prose-slate">
                  <button class="btn btn-primary btn-sm mb-4 block flex w-3/4 sm:text-xs rounded-sm" @click="previewPost(post)"><p class="mx-2 my-0">Preview</p></button>
                  <button class="btn btn-success btn-sm mb-3 block flex w-3/4 sm:text-xs rounded-sm"><p class="mx-2 my-0" @click="approvePost(post)">Approve</p></button>
                  <label class="btn btn-warning btn-sm mb-3 block flex w-3/4 sm:text-xs rounded-sm" for="deny-modal" @click="setCurrentPost(post)"><p class="mx-2 my-0">Reject</p></label>
                  <!-- deny-modal code at end of template -->
                  <button class="btn btn-error btn-sm mb-4 block flex w-3/4 sm:text-xs rounded-sm"><p class="mx-2 my-0">Suspend</p></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- deny modal code start -->
  <input type="checkbox" id="deny-modal" class="modal-toggle" />
  <div class="modal">
    <div class="modal-box flex flex-col justify-center items-center">
      <h3 class="font-bold text-lg flex justify-center">Select a reason for rejection</h3>
      <select class="select select-primary w-full max-w-xs rounded-sm m-4" v-model="rejectValue">
        <option value="sexual" selected>Sexual Content</option>
        <option value="violence">Violence</option>
        <option value="doxing">Doxing</option>
        <option value="racial">Derogatory Racial</option>
      </select>
      <div class="modal-action flex justify-around">
        <label for="deny-modal" class="btn" @click="rejectPost(post)">Reject</label>
        <label for="deny-modal" class="btn">Cancel</label>
      </div>
    </div>
  </div>
  <!-- deny modal code end -->

</template>
