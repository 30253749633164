query ListRecentUsers {
  users: admin_list_new_users{
    id
    createdAt
    username
    displayname
    firstname
    lastname
    email
    phoneNo
    suspended
  }
}
