<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import EditProfileModal from '../modals/EditProfileModal.vue'
import UpdateUser from '@/gql/users/UpdateUser.gql'
import { useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object,
  refetchUser: Function
})

// const userProfile = computed(() => {
//   return {...props.user}
// })

const userProfile = ref({ ...props.user })

const { mutate: updateUser, error: updateError, onDone: updateDone } = useMutation(UpdateUser)
const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)

const handleUpdate = () => {
  try {
    updateUser({
      id: userProfile.value.id,
      firstname: userProfile.value.firstname,
      lastname: userProfile.value.lastname,
      username: userProfile.value.username,
      email: userProfile.value.email,
      displayname: userProfile.value.displayname,
      phoneNo: userProfile.value.phoneNo
    })
  } catch (err) {
    alert(err)
  }
}
const handleCancel = () => {
  userProfile.value = { ...props.user }
}

updateDone(() => {
  props.refetchUser()
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 12, target: userProfile.value.id })
})

watch(updateError, (value) => {
  alert(value)
})
watch(adminActionError, (value) => {
  alert(value)
})

onMounted(() => {
  console.log('USER:::', props.user)
})
</script>

<template>
  <!-- Profile CARD -->
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="profile-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Profile</h3>
    </div>
    <div class="card-body items-center text-center">
      <table class="table table-compact w-full" v-if="props.user">
        <tr>
          <td><p class="font-bold">First Name</p></td>
          <td>{{ props.user.firstname }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Last Name</p></td>
          <td>{{ props.user.lastname }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Username</p></td>
          <td>{{ props.user.username }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Email</p></td>
          <td>{{ props.user.email }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Display Name</p></td>
          <td>{{ props.user.displayname }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Phone #</p></td>
          <td>{{ props.user.phoneNo ? props.user.phoneNo : 'Phone # not provided' }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Post Count</p></td>
          <td>{{ props.user.postCount ? props.user.postCount : '0' }}</td>
        </tr>
      </table>
      <div class="card-actions w-full">
        <label class="btn btn-primary w-full rounded-sm" for="edit-profile-modal">Edit Profile</label>
        <input type="checkbox" class="modal-toggle" id="edit-profile-modal" />
        <EditProfileModal
          v-model:firstname="userProfile.firstname"
          v-model:lastname="userProfile.lastname"
          v-model:username="userProfile.username"
          v-model:email="userProfile.email"
          v-model:displayname="userProfile.displayname"
          v-model:phonenumber="userProfile.phoneNo"
          :updateUser="handleUpdate"
          :handleCancel="handleCancel"
        />
      </div>
    </div>
  </div>
  <!-- End Profile Card-->
</template>
