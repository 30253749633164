<script setup>
import ListUserModerationHistory from '@/gql/users/ListUserModerationHistory.gql'
import { useQuery } from '@vue/apollo-composable'
import { ref, watch, toRef, onMounted } from 'vue'

const props = defineProps({
  user: Object
})

const modHistory = ref(null)

const { result, loading, error, refetch } = useQuery(ListUserModerationHistory, { userId: props.user.id })

watch(result, (value) => {
  console.log('MODHISTORY', value)
  let results = value.posts
  if (results) {
    console.log('Yes! value')
    if (value.posts.length > 6) {
      console.log('greater than six')
      results = value.posts.slice(0, 6)
    }
  }
  console.log('RESULTS', results[1])
  modHistory.value = results
})

function formatDate(dateString) {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
  return date.toLocaleString(undefined, options)
}
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Moderation History</h3>
    </div>
    <div class="card-body items-center text-center prose prose-slate">
      <div v-for="mod in modHistory" class="mb-6 border p-3 bg-slate-100 rounded-sm">
        <p class="m-0 p-0">{{ formatDate(mod.createdAt) }}</p>
        <h4 class="m-0 p-0 break-words">{{ mod.bodyText }}</h4>
        <div v-if="mod.images" class="flex justify-center items-center flex-wrap">
          <img v-for="image in mod.images" :src="image.url" class="w-36" />
        </div>
        <div v-if="mod.video" class="flex justify-center flex-wrap">
          <a :href="mod.video.url" class="w-36 text-blue-400 no-underline hover:scale-125 ease-in transition-all duration-100" target="_blank">Video Link</a>
        </div>
        <div>
          <div class="bg-warning mt-2 rounded-sm">
            <span>Status: </span>
            <span>{{ mod.profanityStatus }}</span>
            <span v-if="mod.profanityAdmin"> by {{ mod.profanityAdmin.username }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
