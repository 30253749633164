<script setup>
import { ref, watch, onMounted } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import GetDashboardStats from '../gql/dashboard/GetDashboardStats.gql'

const dashboardStatsValue = ref(null)


const {result: dashboardStats, loading, error, refetch: refetchDashboardStats} = useQuery(GetDashboardStats)

watch(dashboardStats, (value) => {
  console.log('DASHSTATS, ', value)
  if (value?.stats) {
    dashboardStatsValue.value = value.stats
  }
})

onMounted(() => {
    refetchDashboardStats()
})



</script>

<template>
  <div id="dashboard" class="w-full h-full flex justify-center mt-11 mx-auto">
    <div v-if="loading" class="flex flex-col justify-center items-center w-5/6 prose prose-slate"><h1>Populating Data...</h1><div class="loader"></div></div>
    <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 justify-around w-5/6" v-if="!loading">
      <div class="flex justify-center">
        <div class="card w-full bg-base-100 shadow-xl m-7 rounded-sm" id="profile-card">
            <div class="py-2 pl-4 bg-slate-200 flex items-center justify-center rounded-sm prose prose-slate">
              <h3 class="flex justify-center items-center">User Statistics</h3>
            </div>
            <div class="card-body items-center text-center">
              <table class="table table-compact w-full">
                <tr>
                  <td><p class="font-bold">Total # of users</p></td>
                  <td v-if="dashboardStatsValue">{{ dashboardStatsValue.user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # new sign ups</p></td>
                  <td v-if="dashboardStatsValue">{{ dashboardStatsValue.new_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # accounts suspended</p></td>
                  <td v-if="dashboardStatsValue">{{ dashboardStatsValue.suspended_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most followers</p></td>
                  <td v-if="dashboardStatsValue" v-for="user in dashboardStatsValue.most_followers"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most friends</p></td>
                  <td v-if="dashboardStatsValue" v-for="user in dashboardStatsValue.most_friends"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most posts</p></td>
                  <td v-if="dashboardStatsValue" v-for="user in dashboardStatsValue.most_posts"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most comments</p></td>
                  <td>{{  }}</td>
                </tr>
              </table>
            </div>
          </div>
      </div>
      <div class="flex justify-center">
        <div class="card w-full bg-base-100 shadow-xl m-7 rounded-sm" id="profile-card">
            <div class="py-2 pl-4 bg-slate-200 flex items-center justify-center rounded-sm prose prose-slate">
              <h3 class="flex justify-center items-center">LiveStream Stats</h3>
            </div>
            <div class="card-body items-center text-center">
              <table class="table table-compact w-full">
                <!-- <tr>
                  <td><p class="font-bold">Total # of users</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # new sign ups</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.new_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # accounts suspended</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.suspended_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most followers</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_followers"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most friends</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_friends"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most posts</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_posts"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most comments</p></td>
                  <td>{{  }}</td>
                </tr> -->
              </table>
            </div>
          </div>
      </div>
      <div class="flex justify-center">
        <div class="card w-full bg-base-100 shadow-xl m-7 rounded-sm" id="profile-card">
            <div class="py-2 pl-4 bg-slate-200 flex items-center justify-center rounded-sm prose prose-slate">
              <h3 class="flex justify-center items-center">Open Moderations</h3>
            </div>
            <div class="card-body items-center text-center">
              <table class="table table-compact w-full">
                <!-- <tr>
                  <td><p class="font-bold">Total # of users</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # new sign ups</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.new_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">Total # accounts suspended</p></td>
                  <td v-if="userStatsValue">{{ userStatsValue.suspended_user_count }}</td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most followers</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_followers"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most friends</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_friends"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most posts</p></td>
                  <td v-if="userStatsValue" v-for="user in userStatsValue.most_posts"><router-link :to="`/user/${user.id}`" class="bg-transparent">
                    <p class="font-bold">{{ user.username }}</p>
                  </router-link></td>
                </tr>
                <tr>
                  <td><p class="font-bold">User with most comments</p></td>
                  <td>{{  }}</td>
                </tr> -->
              </table>
            </div>
          </div>
      </div>
      <!-- <div class="bg-blue-300">2</div>
      <div class="bg-green-300">3</div>
      <div class="bg-orange-300">4</div> -->
    </div>
    <!-- <div class="grid grid-cols-6 gap-4 prose prose-slate max-w-full bg-white w-5/6">
      <div class="col-start-1 col-span-6 flex items-center h-20"><h2 class="m-0">Dashboard</h2></div>
      <div class="col-start-1 col-span-2 flex flex-col justify-center items-center">
        <p class="m-0 font-bold">{{ userCountValue }}</p>
        <div class="overflow-x-auto">
          <table class="table-compact w-full">
            <thead>
              <tr>
                <th></th>
                <th>Post</th>
                <th>Author</th>
                <th>Views</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>3</th>
                <td>Quality Control Specialist</td>
                <td>Cy Ganderton</td>
                <td>1,430</td>
              </tr>
              <tr>
                <th>2</th>
                <td>Desktop Support Technician</td>
                <td>Hart Hagerty</td>
                <td>2,013</td>
              </tr>
              <tr>
                <th>1</th>
                <td>Tax Accountant</td>
                <td>Brice Swyre</td>
                <td>4,185</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-start-3 col-span-2 flex flex-col justify-center items-center">
        <p class="m-0 font-bold">Top 10 Active Livestreams</p>
        <div class="overflow-x-auto">
          <table class="table-compact w-full">
            <thead>
              <tr>
                <th></th>
                <th>Post</th>
                <th>Author</th>
                <th>Views</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>3</th>
                <td>Quality Control Specialist</td>
                <td>Cy Ganderton</td>
                <td>1,430</td>
              </tr>
              <tr>
                <th>2</th>
                <td>Desktop Support Technician</td>
                <td>Hart Hagerty</td>
                <td>2,013</td>
              </tr>
              <tr>
                <th>1</th>
                <td>Tax Accountant</td>
                <td>Brice Swyre</td>
                <td>4,185</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-start-5 col-span-2 flex flex-col justify-center items-center">
        <p class="m-0 font-bold">Top 10 Active Groups</p>
        <div class="overflow-x-auto">
          <table class="table-compact w-full">
            <thead>
              <tr>
                <th></th>
                <th>Post</th>
                <th>Author</th>
                <th>Views</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>3</th>
                <td>Quality Control Specialist</td>
                <td>Cy Ganderton</td>
                <td>1,430</td>
              </tr>
              <tr>
                <th>2</th>
                <td>Desktop Support Technician</td>
                <td>Hart Hagerty</td>
                <td>2,013</td>
              </tr>
              <tr>
                <th>1</th>
                <td>Tax Accountant</td>
                <td>Brice Swyre</td>
                <td>4,185</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-start-1 col-span-1 flex flex-col justify-center items-center">Moderation Queue</div>
      <div class="col-start-2 col-span-1 flex flex-col justify-center items-center">Verification Queue</div>
      <div class="col-start-3 col-span-2 flex flex-col justify-center items-center">Last 10 Moderation Actions</div>
      <div class="col-start-5 col-span-2 flex flex-col justify-center items-center">Top Engaged Users</div>
      <div class="col-start-1 col-span-3 flex flex-col justify-center items-center"><p class="m-0 font-bold">Upload Footprints this Week</p></div>
      <div class="col-start-4 col-span-3 flex flex-col justify-center items-center">Current Running Events</div>
      <div class="col-start-1 col-span-3 flex flex-col justify-center items-center">Upload Footprint this Month</div>
    </div> -->
  </div>
</template>

<style>
.loader {
  margin: auto;
  border: 20px solid #EAF0F6;
  border-radius: 50%;
  border-top: 20px solid #FF7A59;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
