<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import CustomFilterInput from '@/components/CustomFilterInput.vue'
import ListRestrictedEmails from '@/gql/restrictedEmails/ListRestrictedEmailDomains.gql'
import AddRestrictedEmails from '@/gql/restrictedEmails/AddRestrictedEmailDomain.gql'
import RemoveRestrictedEmails from '@/gql/restrictedEmails/RemoveRestrictedEmailDomain.gql'
import { useQuery, useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const searchText = ref('')
const newUrl = ref(null)
const urlArray = ref(null)

const filteredArray = computed(() => {
  console.log('COMPUTED', urlArray.value)
  if (urlArray.value) {
    return urlArray.value.filter((url) => {
      return url.toLowerCase().indexOf(searchText.value.toLowerCase()) != -1
    })
  }
})

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { result, loading, error, refetch } = useQuery(ListRestrictedEmails)

const { mutate: deleteItem, onDone: deleteDone } = useMutation(RemoveRestrictedEmails, () => ({
  update: (cache, { data }, { variables }) => {
    const { urls } = cache.readQuery({ query: ListRestrictedEmails })
    if (variables.domain && urls) {
      const updatedUrls = urls.filter((url) => url !== variables?.domain)
      cache.writeQuery({
        query: ListRestrictedEmails,
        data: {
          urls: [...updatedUrls]
        }
      })
    }
  }
}))

const { mutate: insertItem, onDone: insertDone } = useMutation(AddRestrictedEmails, () => ({
  update: (cache, { data }, { variables }) => {
    const newUrl = data?.admin_add_restricted_email_domain
    const { urls } = cache.readQuery({ query: ListRestrictedEmails })
    if (newUrl === true && variables.domain) {
      // urls.push(variables.domain)
      cache.writeQuery({
        query: ListRestrictedEmails,
        data: {
          urls: [...urls, variables.domain]
        }
      })
    }
  }
}))

// deleteDone(res => refetch())
// insertDone(res => refetch())

const createUrl = () => {
  const url = prompt('What url would you like to block?')
  console.log('URL', url)
  if (url === null) {
    return
  }
  insertItem({ domain: url })
  newUrl.value = url
}

const handleDelete = async (url) => {
  try {
    await deleteItem({ domain: url })
  } catch (error) {
    alert(error)
  }
  try {
    const adminID = auth.getUserId()
    await createAdminAction({ userId: adminID, actionId: 17, target: url })
  } catch (error) {
    alert(error)
  }
}

onMounted(() => {
  refetch()
})

watch(result, (value) => {
  console.log('EMAILS::', value)
  if (value?.urls) {
    urlArray.value = value.urls
    console.log('STATE SET RESULT')
  }
})

watch(loading, (value) => {
  if (value === false) {
    if (result.value.urls) {
      urlArray.value = result.value.urls
      console.log('STATE SET LOADING')
    }
  }
})

insertDone((res) => {
  if (res.data?.admin_add_restricted_email_domain) {
    const adminID = auth.getUserId()
    createAdminAction({ userId: adminID, actionId: 16, target: newUrl.value })
    newUrl.value = null
  }
})

</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex flex-wrap justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0 w-96">Blocked Emails</h2>
        <form v-on:submit.prevent="onSubmit" class="w-1/2 flex md:justify-end sm:justify-start">
          <CustomFilterInput v-model="searchText" class="w-[100%] rounded-sm" />
        </form>
        <div class="btn btn-primary rounded-sm min-h-[2rem] h-[2.3rem]" @click="createUrl">Block New Domain</div>
      </div>
      <div v-if="loading"><p>Loading domains...</p></div>
      <div class="overflow-x-auto mt-12" v-if="!loading">
        <table class="table border border-slate-300 w-full">
          <thead>
            <tr>
              <th class="rounded-sm"></th>
              <th>Domain</th>
              <th class="rounded-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover" v-for="url in filteredArray">
              <th></th>
              <td>{{ url }}</td>
              <td><button class="btn btn-primary btn-sm" @click="handleDelete(url)">Unblock</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
