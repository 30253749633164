query ListModerationPosts {
  posts: admin_list_moderation_posts {
    id
    type
    bodyText
    postedByUser {
      id
      username
    }
    images
    video
    profanityStatus
    createdAt
  }
}
