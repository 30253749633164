query FindSuspendedUsers($query:String!){
  users: admin_find_suspended_users(query:$query) {
    userId
    user {
      id
      email
      username
      firstname
      lastname
      profilePhotoURL
    }
    admin {
      id
      displayname
    }
    createdAt
    reason
  }
}
