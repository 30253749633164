<script setup>
import ListUserReports from "@/gql/reports/ListUserReports.gql";
import { useQuery } from "@vue/apollo-composable";
import { watch, ref, onMounted } from 'vue'
import {formatDate} from '@/services/utilities.js'

const reports = ref(null)

const { result: userReports, loading, error, refetch } = useQuery(ListUserReports)

watch(userReports, (value) => {
  // console.log('userReports', value)
  reports.value = value.reports
})

onMounted(() => {
  refetch();
})


</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="reportedusers" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Reported Users</h2>
        <div class="btn btn-primary rounded-sm">Wizard Mode</div>
      </div>
      <div class="mt-10">
        <div class="overflow-x-auto" v-if="reports">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Total Reports</th>
                <th class="rounded-sm">Latest Report</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in reports">
                <th></th>
                <router-link :to="`/user/${report.user.id}`" class="bg-transparent">
                                <td class="bg-transparent">
                                    <p class="font-bold hover:scale-125 ease-in duration-100">{{ report.user.username }}</p>
                                </td>
                            </router-link>
                <td>{{ report.total_reports }}</td>
                <td>{{ formatDate(report.latest_report) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
