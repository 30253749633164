<script setup>
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from "vue-router";
import { toRef, ref, watch, onMounted } from 'vue'
import GetAuditLog from '@/gql/users/GetAuditLog.gql'
import {formatDate} from '@/services/utilities.js'



const route = useRoute()
const userId = toRef(route.params, "id")
const username = toRef(route.params, "username")

const auditLog = ref(null)

const {result: auditResult, error: auditError, refetch} = useQuery(GetAuditLog, () => ({userId: userId.value}))


watch(auditResult, (value) => {
  console.log('AUDITRESULT', value)
  if (value.log) {
    auditLog.value = value.log
  }
})

onMounted(() => {
  refetch()
})




</script>

<template>
    <div id="page-container" class="w-full h-full flex justify-center mt-11">
      <div id="finduser" class="w-5/6">
        <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
          <h2 class="justify-self-center mb-0">{{ username }} Audit Log</h2>
          <!-- <div class="btn btn-primary rounded-sm">Create Notification</div> -->
        </div>
          <div class="overflow-x-auto mt-12">
            <table class="table border border-slate-300 w-full">
              <thead>
                <tr>
                  <th class="rounded-sm"></th>
                  <th class="rounded-sm">Created At</th>
                  <th class="rounded-sm">Action</th>
                  <th class="rounded-sm">Target</th>
                  <th class="rounded-sm">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr class="hover" v-for="audit in auditLog">
                  <th></th>
                  <td>{{ formatDate(audit.created_at) }}</td>
                  <td>{{ audit.action }}</td>
                  <td>{{ audit.target }}</td>
                  <td>{{ audit.notes}}</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </template>