
fragment BasicNotification on Notification {
  id
  body
  typeNotification
  status
  url
  createdAt
  updatedAt
}

query ListNotifications {
  notifications: list_my_notifications {
    ...BasicNotification
  }
}
