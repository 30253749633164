//Need suspended Users in the system

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import CustomFilterInput from "@/components/CustomFilterInput.vue";
import FindSuspendedUsers from '@/gql/suspendeds/FindSuspendedUsers.gql';
import { useQuery } from "@vue/apollo-composable"
import {formatDate} from '@/services/utilities.js'


const usersArray = ref([])
const searchText = ref('');

const filteredArray = computed(() => {
  return usersArray.value.filter((user) => {
    // console.log('USER: ', user)
    return user.user.username.toLowerCase().indexOf(searchText.value.toLowerCase()) != -1
  })
})


const { result, loading, error, load, refetch } = useQuery(FindSuspendedUsers, () => ({
  query: '',
}));

// const onSubmit = () => {
//   if (searchText.value !== "") {
//     if (result.value === undefined && !error.value) {
//       load();
//     } else {
//       refetch();
//     }
//   }
// }


watch(result, value => {
  console.log('VALUE', value)
  if (value?.users) {
    usersArray.value = value?.users
  }
})

onMounted(() => {
  refetch()
})



// const {result: suspendedUsers, loading, error, refetch} = useQuery(FindSuspendedUsers)

// watch(suspendedUsers, value => {
//   console.log('SUS', value)
// })

</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <form v-on:submit.prevent="onSubmit" class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Suspended Users</h2>
        <CustomFilterInput v-model="searchText" class="sm:w-[50%] xl:w-[60%]" />
      </form>
      <div class="mt-10">
        <div class="overflow-x-auto" v-if="usersArray">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Email</th>
                <th>Suspended By</th>
                <th>Reason</th>
                <th class="rounded-sm">Suspension Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredArray">
                <th></th>
                <router-link :to="`/user/${user.user.id}`" class="bg-transparent">
                                <td class="bg-transparent">
                                    <p class="font-bold hover:scale-125 ease-in duration-100">{{ user.user.username }}</p>
                                </td>
                            </router-link>
                <td>{{ user.user.email }}</td>
                <router-link :to="`/user/${user.admin.id}`" class="bg-transparent">
                                <td class="bg-transparent">
                                    <p class="font-bold hover:scale-125 ease-in duration-100">{{ user.admin.displayname }}</p>
                                </td>
                            </router-link>
                <td>{{ user.reason }}</td>
                <td>{{ formatDate(user.createdAt) }}</td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Email</th>
                <th>Suspended By</th>
                <th>Reason</th>
                <th class="rounded-sm">Suspension Date</th>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
