
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'

const getHeaders = () => {
  if (localStorage.access_token) {
    return {
      Authorization: `Bearer ${localStorage.access_token}`
    }
  }
  return {}
}

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: import.meta.env.VITE_GRAPH_ENDPOINT,
  // uri: 'https://graph.clouthub.dev',
  headers: getHeaders()
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

export default apolloClient
