<script setup>
import ListVerificationTasks from "@/gql/verification/ListVerificationTasks.gql";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { ref, watch, onMounted } from "vue";
import UserVerificationModal from '@/components/modals/UserVerificationModal.vue'
import SetBadgeType from '@/gql/verification/SetBadgeType.gql'



const verifications = ref([])

const closeModal = () => {
    document.getElementById('verify-modal').checked = false;
}

const { result: verificationTasks, loading, error, refetch } = useQuery(ListVerificationTasks);

watch(verificationTasks, (value) => {
  console.log("TASKS", value);
  if (value?.users) {
    verifications.value = value.users;
  }
})

onMounted(() => {
  refetch()
});

</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="verifyusers" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Verification Requests</h2>
      </div>
      <div v-if="verifications.length === 0">There are no current verifications pending...</div>
      <div class="mt-10" v-if="verifications.length > 0">
        <div class="overflow-x-auto">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Displayname</th>
                <th>Badge Request</th>
                <th>Badge Type</th>
                <th class="rounded-sm">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in verifications">
                <th></th>
                <td>{{ user.username }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td>{{ user.displayname }}</td>
                <td>{{ user.requestedBadgeType }}</td>
                <td>{{ user.badgeType }}</td>
                <td>
                  <label class="btn btn-primary btn-sm" for="verify-modal">Verify</label>
                  <input type="checkbox" id="verify-modal" class="modal-toggle" />
                  <UserVerificationModal :user="user" :closeModal="closeModal" :refetch="refetch"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
