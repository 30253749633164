mutation CreateUser($displayname:String!,$email:String!,$firstname:String!,$lastname:String!,$username:String!,$password:String!){
  user:admin_create_user(
    displayname:$displayname,
    email:$email,
    firstname:$firstname,
    lastname:$lastname,
    username:$username,
    password:$password,
  ) {
    id
    displayname
    username
    firstname
    lastname
  }
}
