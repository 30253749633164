<script setup>
import { ref, onBeforeMount, watch } from 'vue'
import SetUserScores from '@/gql/users/SetUserScores.gql'
import { useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object,
  refetchUser: Function
})

const connectionsScore = ref(0)
const channelsScore = ref(0)

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const {
  mutate: setScores,
  onDone: setScoresDone,
  error: setScoresError
} = useMutation(SetUserScores, () => ({
  update: (cache, { data }) => {
    console.log('DATA', data)
  }
}))

const handleCancel = () => {
  if (props.user.priorityScore) {
    connectionsScore.value = props.user.priorityScore
  } else {
    connectionsScore.value = 0
  }
  if (props.user.channelPriorityScore) {
    channelsScore.value = props.user.channelPriorityScore
  } else {
    channelsScore.value = 0
  }
}

onBeforeMount(() => {
  if (props.user.priorityScore) {
    connectionsScore.value = props.user.priorityScore
  }
  if (props.user.channelPriorityScore) {
    channelsScore.value = props.user.channelPriorityScore
  }
})

const handleSetScore = async () => {
  if (connectionsScore || channelsScore) {
    try {
      setScores({ userId: props.user.id, priorityScore: +connectionsScore.value, channelPriorityScore: +channelsScore.value })
    } catch (err) {
      alert(err)
    }
  }
}

setScoresDone(() => {
  const adminID = auth.getUserId()
  try {
    createAdminAction({ userId: adminID, actionId: 21, target: props.user.id })
  } catch (error) {
    alert(error)
  }
  props.refetchUser()
})

watch(setScoresError, (value) => {
  alert(value)
})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Search Ranking</h3>
    </div>
    <div class="card-body items-center text-center flex">
      <div class="mb-4 flex w-full">
        <p class="w-full flex justify-start">Connections Priority</p>
        <p class="w-full">{{ props.user.priorityScore || 'Not Set' }}</p>
      </div>
      <div class="mb-4 flex w-full">
        <p class="w-full flex justify-start">Channels Priority</p>
        <p class="w-full">{{ props.user.channelPriorityScore || 'Not Set' }}</p>
      </div>
      <div class="card-actions w-full">
        <label class="btn btn-primary w-full rounded-sm" for="score-modal">Edit Scores</label>
        <input type="checkbox" id="score-modal" class="modal-toggle" />
        <!-- Set scores Modal -->
        <div class="modal">
          <div class="modal-box flex flex-col justify-center items-center prose prose-slate">
            <h3 class="font-bold text-lg flex justify-center">Edit Scores</h3>
            <div class="flex justify-start mb-4 w-5/6">
              <p class="m-0 flex justify-start">Connections Priority:</p>
              <select class="select select-primary select-sm w-1/2 max-w-xs" v-model="connectionsScore">
                <option selected disabled>Set priority</option>
                <option value="10">Very Low Visibility (10)</option>
                <option value="30">Low visibility (30)</option>
                <option value="50">Medium visibility (50)</option>
                <option value="70">High visibility (70)</option>
                <option value="90">Very high visibility (90)</option>
              </select>
            </div>
            <div class="flex justify-start w-5/6">
              <p class="m-0 flex justify-start">Channels Priority:</p>
              <select class="select select-primary select-sm w-1/2 max-w-xs" v-model="channelsScore">
                <option selected disabled>Set priority</option>
                <option value="10">Very Low Visibility (10)</option>
                <option value="30">Low visibility (30)</option>
                <option value="50">Medium visibility (50)</option>
                <option value="70">High visibility (70)</option>
                <option value="90">Very high visibility (90)</option>
              </select>
            </div>
            <div class="modal-action flex justify-around w-5/6">
              <label for="score-modal" class="btn w-24" @click="handleSetScore">Save</label>
              <label for="score-modal" class="btn w-24" @click="handleCancel">Cancel</label>
            </div>
          </div>
        </div>
        <!-- end Set scores modal -->
      </div>
    </div>
  </div>
</template>
