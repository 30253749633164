<script setup>
import ResetPassword from '@/gql/users/ResetPassword.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'
import { useMutation } from '@vue/apollo-composable'
import { watch } from 'vue'

const props = defineProps({
  user: Object
})

const { mutate: resetPassword, onDone: resetDone, error: resetError } = useMutation(ResetPassword, () => ({}))

const { mutate: createAdminAction, onDone: adminActionDone, error: adminActionError } = useMutation(CreateAdminAction)

resetDone((result) => {
  const adminID = auth.getUserId()
  alert('Password has been reset')
  if (result) {
    createAdminAction({ userId: adminID, actionId: 2, target: props.user.id })
  }
})

const handleResetPassword = () => {
  const password = prompt('Input new password')
  if (password) {
    resetPassword({ userId: props.user.id, password: password.trim() })
  }
}

watch(adminActionError, (value) => {
  if (value) {
    alert(value)
  }
})

watch(resetError, (value) => {
  if (value) {
    alert(value)
  }
})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Reset Password</h3>
    </div>
    <div class="card-body items-center text-center flex">
      <!-- <div v-if="!user.suspended"><p>Account Active</p></div>
          <div v-if="user.suspended"><p>Account Suspended</p></div> -->
      <div class="card-actions w-full">
        <button class="btn btn-primary w-full rounded-sm" @click="handleResetPassword">Reset Password</button>
        <!-- <button class="btn btn-error w-full rounded-sm" v-if="user.suspended" @click="handleUnsuspend">Unsuspend Account</button> -->
      </div>
    </div>
  </div>
</template>
