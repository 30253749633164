<script setup>
import SuspendUser from '@/gql/suspendeds/SuspendUser.gql'
import UnsuspendUser from '@/gql/suspendeds/UnsuspendUser.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '../../services/auth.js'
import { useMutation } from '@vue/apollo-composable'
import { ref, watch, onBeforeMount } from 'vue'

const props = defineProps({
  user: Object
})

onBeforeMount(() => {})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Audit Log</h3>
    </div>
    <div class="card-body items-center text-center flex">
      <!-- <div v-if="!user.suspended"><p>Account Active</p></div>
      <div v-if="user.suspended"><p>Account Suspended</p></div> -->
      <div class="card-actions w-full">
        <router-link :to="`/auditlog/${props.user.id}/${props.user.username}`" class="bg-transparent"><button class="btn btn-primary w-full rounded-sm">View Audit Log</button></router-link>
        <!-- <button class="btn btn-error w-full rounded-sm" v-if="user.suspended" @click="handleUnsuspend">Unsuspend Account</button> -->
      </div>
    </div>
  </div>
</template>
