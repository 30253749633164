<script setup>
import ListNotifications from '@/gql/notifications/ListNotifications.gql'
import UpdateNotification from '@/gql/notifications/UpdateNotification.gql'
import CreateNotification from '@/gql/notifications/CreateNotification.gql'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { formatDate } from '@/services/utilities.js'
import CreateNotificationModal from '@/components/CreateNotificationModal.vue'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

import { ref, watch, onMounted } from 'vue'

const notifications = ref(null)
const newNotification = ref({ status: '', typeNotification: '', body: '', url: '' })

const { result: notificationsResult, error: notificationsError, refetch } = useQuery(ListNotifications)

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: updateNotification, error: updateError, onDone: updateDone } = useMutation(UpdateNotification)
const { mutate: createNewNotification, error: createError, onDone: createDone } = useMutation(CreateNotification)

watch(notificationsResult, (value) => {
  console.log('notifications', value)
  if (notificationsResult.value) {
    notifications.value = value.notifications
  }
})

const setStatus = async (notification, status) => {
  if (notification && status) {
    try {
      await updateNotification({ ...notification, status: status })
    } catch (e) {
      alert(e)
    }
  }
}

const deactivateNotification = (notification) => {
  if (confirm('Are you sure you wish to deactivate this notification?')) {
    setStatus(notification, 'deactivated')
  }
}
const activateNotification = (notification) => {
  if (confirm('Are you sure you wish to activate this notification?')) {
    setStatus(notification, 'active')
  }
}

const createNotification = async () => {
  if (newNotification.value.status !== '' && newNotification.value.type !== '' && newNotification.value.message !== '' && newNotification.value.url !== '') {
    try {
      createNewNotification(newNotification.value)
    } catch (e) {
      alert(e)
    }
  } else {
    alert('Please fill in all the required fields!')
  }
}

onMounted(() => {
  refetch()
})

updateDone(() => {
  refetch()
})

createDone((res) => {
  console.log('RESULT: ', res)
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 24, 
    target: 'Notification with ID of ' + res.data.create_notification.id
  })
  refetch()
})
</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Notifications</h2>
        <label class="btn btn-primary rounded-sm" for="create-notification-modal">Create Notification</label>
        <input type="checkbox" class="modal-toggle" id="create-notification-modal" />
        <CreateNotificationModal
          v-model:status="newNotification.status"
          v-model:type="newNotification.typeNotification"
          v-model:message="newNotification.body"
          v-model:url="newNotification.url"
          :createNotification="createNotification"
        />
      </div>
      <div class="overflow-x-auto mt-12" v-if="notifications">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="rounded-sm"></th>
              <th>ID</th>
              <th>Created on</th>
              <th>Notification</th>
              <th>Status</th>
              <th>Type</th>
              <th class="rounded-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover" v-for="notification in notifications">
              <th class="rounded-sm"></th>
              <td>{{ notification.id }}</td>
              <td>{{ formatDate(notification.createdAt) }}</td>
              <td class="max-w-sm overflow-hidden break-words">{{ notification.body }}</td>
              <td>{{ notification.typeNotification }}</td>
              <td>{{ notification.status }}</td>
              <td class="rounded-sm">
                <button v-if="notification.status === 'active'" class="btn btn-warning btn-sm mr-6" @click="deactivateNotification(notification)">Deactivate</button>
                <button v-if="notification.status === 'deactivated'" class="btn btn-success btn-sm mr-6" @click="activateNotification(notification)">Activate</button>
                <!-- <button class="btn btn-primary btn-sm" @click="">Preview</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
