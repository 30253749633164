query {
  reports: admin_list_user_reports{
    id
    total_reports
    latest_report
    user {
      id
      username
      profilePhotoURL
    }
  }
}
