query ListVerificationTasks {
  users: admin_list_verification_tasks {
    id
    username
    firstname
    lastname
    displayname
    requestedBadgeType
    verificationUrl
    profilePhotoURL
    badgeType
  }
}
