<script setup>
defineProps(["enabled", "name", "address", "updatePayments", "handleCancel"]);
const emit = defineEmits(["update:enabled", "update:name", "update:address"]);

const toggleSwitch = (value) => {
  // console.log('SWITCHVALUE', value)
  if (value === false) {
    emit('update:name', '')
    emit('update:address', '')
  }
  emit('update:enabled', value)
}
</script>

<template>
  <div class="modal">
    <div class="modal-box prose prose-slate rounded-sm">
      <h3 class="font-bold text-lg flex justify-center">Edit Payment Settings</h3>
      <div>
        <label class="label cursor-pointer">
          <span class="label-text"><p class="my-2 flex justify-start">Enable Payments</p></span>
          <input type="checkbox" name="enablePayments-toggle" placeholder="Input character limit..." class="toggle toggle-success" @change="toggleSwitch(!enabled)" :checked="enabled"/>
        </label>
        <label for="name-input"><p class="my-2 flex justify-start">Payment Name</p></label>
        <input type="text" name="name-input" placeholder="Payment name..." class="bg-blue-100 border border-slate-400 px-4 w-full" 
        @input="$emit('update:name', $event.target.value)" :value="name" :disabled="!enabled"/>
        <label for="address-input"><p class="my-2 flex justify-start">Payment Address</p></label>
        <input type="text" name="address-input" placeholder="Payment address..." class="bg-blue-100 border border-slate-400 px-4 w-full" 
        @input="$emit('update:address', $event.target.value)" :value="address" :disabled="!enabled"/>
      </div>
      <div class="modal-action flex justify-around">
        <label for="edit-payments-modal" class="btn" @click="updatePayments()">Update</label>
        <label for="edit-payments-modal" class="btn" @click="handleCancel()">Cancel</label>
      </div>
    </div>
  </div>
</template>
