fragment BasicNotification on Notification {
  id
  body
  typeNotification
  status
  url
  createdAt
  updatedAt
}

mutation UpdateNotification(
  $id:ID!,
  $status:String!,
  $typeNotification:String!,
  $body:String!,
  $url:String,
) {
  update_notification(
    id:$id,
    status:$status,
    typeNotification:$typeNotification,
    body:$body,
    url:$url,
  ) {
    ...BasicNotification
  }
}
