query FindUsers($filterKey:String!, $filterValue:String!) {
  users: admin_find_users(filterKey:$filterKey, filterValue:$filterValue){
    id
    username
    displayname
    firstname
    lastname
    email
    phoneNo
  }
}
