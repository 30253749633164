<script setup>
import { ref, watch, onMounted } from "vue";
import CustomFilterInput from "@/components/CustomInput.vue";
import ListChannels from '@/gql/channels/ListChannels.gql';
import { useQuery } from "@vue/apollo-composable";

const searchText = ref("");
const channels = ref([])

const { result: userChannels, loading, error, refetch } = useQuery(ListChannels);

watch(userChannels, (value) => {
  console.log(value)
  if (value?.users) {
    channels.value = value.users;
  }
});

onMounted(() => {
  refetch()
})


</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Live Channels</h2>
        <CustomFilterInput v-model="searchText" class="w-[60%]" />
      </div>
      <div class="mt-10">
        <div v-if="loading"><p>Loading channels...</p></div>
        <div class="overflow-x-auto" v-if="!loading">
          <table class="table table-compact w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>Username</th>
                <th>Channel Name</th>
                <th>True News</th>
                <th>Channel #</th>
                <th class="rounded-sm">Stream Key</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in channels">
                <th></th>
                <router-link :to="`/user/${user.id}`">
                  <td>{{ user.username }}</td>
                </router-link>
                <td>{{ user.channelName }}</td>
                <td>{{ user.channelTrueNews }}</td>
                <td>{{ user.channelNumber || 'None' }}</td>
                <td>{{ user.liveStreamKey }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
