<!-- need to add suspend reasoning into this component -->
<script setup>
import SuspendUser from '@/gql/suspendeds/SuspendUser.gql'
import UnsuspendUser from '@/gql/suspendeds/UnsuspendUser.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '../../services/auth.js'
import { useMutation } from '@vue/apollo-composable'
import { ref, watch } from 'vue'

const props = defineProps({
  user: Object
})

const suspendReason = ref('testing')

const {
  mutate: suspendUser,
  onDone: suspendDone,
  error: suspendError
} = useMutation(SuspendUser, () => ({
  update: () => {}
}))
const {
  mutate: unSuspendUser,
  onDone: unSuspendDone,
  error: unSuspendError
} = useMutation(UnsuspendUser, () => ({
  update: () => {}
}))

suspendDone((result) => {
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 3, target: props.user.id })
})
unSuspendDone((result) => {
  const adminID = auth.getUserId()

  createAdminAction({ userId: adminID, actionId: 30, target: props.user.id })
})

const { mutate: createAdminAction, onDone: adminActionDone, error: adminActionError } = useMutation(CreateAdminAction)

const handleSuspend = () => {
  if (props.user.id && suspendReason.value !== '') {
    suspendUser({ id: props.user.id, reason: suspendReason.value })
  }
}
const handleUnsuspend = () => {
  if (props.user.id) {
    unSuspendUser({ id: props.user.id })
  }
}
watch(suspendError, (value) => {
  if (value) {
    alert(value)
  }
})
watch(unSuspendError, (value) => {
  if (value) {
    alert(value)
  }
})
watch(adminActionError, (value) => {
  if (value) {
    alert(value)
  }
})
</script>

<template>
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Status</h3>
    </div>
    <div class="card-body items-center text-center flex">
      <div v-if="!user.suspended"><p>Account Active</p></div>
      <div v-if="user.suspended"><p>Account Suspended</p></div>
      <div class="card-actions w-full">
        <button class="btn btn-primary w-full rounded-sm" v-if="!user.suspended" @click="handleSuspend">Suspend Account</button>
        <button class="btn btn-error w-full rounded-sm" v-if="user.suspended" @click="handleUnsuspend">Unsuspend Account</button>
      </div>
    </div>
  </div>
</template>
