<script setup>
import { useRoute } from "vue-router";
import { watch, ref, toRef, onMounted, onBeforeMount } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import GetUser from "@/gql/users/GetUser.gql";
import UserComplaintsCard from "@/components/userDetails/UserComplaintsCard.vue";
import UserProfileCard from "@/components/userDetails/UserProfileCard.vue";
import UserChannelCard from "@/components/userDetails/UserChannelCard.vue";
import UserVerificationCard from '@/components/userDetails/UserVerificationCard.vue'
import UserSessionsCard from "@/components/userDetails/UserSessionsCard.vue";
import UserRssCard from "@/components/userDetails/UserRssCard.vue";
import UserSearchRankCard from "@/components/userDetails/UserSearchRankCard.vue";
import UserStatusCard from "@/components/userDetails/UserStatusCard.vue";
import UserModHistoryCard from "@/components/userDetails/UserModHistoryCard.vue";
import UserPasswordCard from "@/components/userDetails/UserPasswordCard.vue";
import UserLimitsCard from "@/components/userDetails/UserLimitsCard.vue";
import UserPaymentsCard from "@/components/userDetails/UserPaymentsCard.vue";
import UserAuditCard from "@/components/userDetails/UserAuditCard.vue";
import auth from '../../services/auth.js'


const user = ref(null)
const isAdmin = ref(null)
const isLoading = ref(false)

const route = useRoute()

const userId = toRef(route.params, "id")

const { result, loading, error, load, refetch } = useLazyQuery(GetUser, () => ({ id: userId.value }))

watch(result, (value) => {
  user.value = value.user;
  console.log(value.user)
})

watch(loading, value => {
  isLoading.value = value
})

onMounted(() => {
  load();
})

onBeforeMount(() => {
  console.log('ISADMIN', auth.isAdmin(userId.value))
  if (auth.isAdmin(userId.value)) {
    isAdmin.value = true
  } else {
    isAdmin.value = false
  }
})

</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="userdetails" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">User Details</h2>
        <div class="btn btn-primary rounded-sm">View on CloutHub</div>
      </div>
      <div class="flex justify-center" v-if="isLoading">
        <h1>Loading user data...</h1>
      </div>
      <div class="grid grid-cols-2 gap 4" v-if="user">
        <div id="left-column" class="flex flex-col items-end">
          <UserProfileCard :user=user :refetchUser=refetch />
          <UserChannelCard :user=user />
          <UserComplaintsCard :user=user />
          <UserSearchRankCard :user=user :refetchUser=refetch />
          <UserStatusCard :user=user />
          <UserModHistoryCard :user=user />
          <UserLimitsCard :user=user :refetchUser=refetch />
        </div>
        <div id="right-column" class="flex flex-col items-start">
          <UserVerificationCard :user=user :refetchUser=refetch />
          <UserSessionsCard :user=user />
          <UserRssCard :user=user />
          <UserPasswordCard :user=user />
          <UserPaymentsCard :user=user :refetchUser=refetch />
          <UserAuditCard :user=user v-if="isAdmin" />
        </div>
        <!-- <div class="col-span-2 bg-red-200">2</div> -->
      </div>

    </div>
  </div>
</template>
