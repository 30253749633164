<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import CustomFilterInput from '@/components/CustomFilterInput.vue'
import ListRestrictedUrls from '@/gql/restrictedUrls/ListRestrictedUrls.gql'
import InsertRestrictedUrl from '@/gql/restrictedUrls/InsertRestrictedUrl.gql'
import DeleteRestrictedUrl from '@/gql/restrictedUrls/DeleteRestrictedUrl.gql'
import { useQuery, useMutation } from '@vue/apollo-composable'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const searchText = ref('')
const urlArray = ref([])

const filteredArray = computed(() => {
  return urlArray.value.filter((url) => {
    return url.host.toLowerCase().indexOf(searchText.value.toLowerCase()) != -1
  })
})

const { result, loading, error, refetch } = useQuery(ListRestrictedUrls)

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: deleteItem, onDone: deleteDone } = useMutation(DeleteRestrictedUrl, () => ({
  update: (cache, { data }, { variables }) => {
    const { urls } = cache.readQuery({ query: ListRestrictedUrls })
    if (variables && urls) {
      const updatedUrls = urls.filter((url) => url.id !== variables?.id)
      cache.writeQuery({
        query: ListRestrictedUrls,
        data: {
          urls: [...updatedUrls]
        }
      })
    }
  }
}))

const { mutate: insertItem, onDone: insertDone } = useMutation(InsertRestrictedUrl, () => ({
  update: (cache, { data }) => {
    const newUrl = data?.admin_insert_restricted_url
    const { urls } = cache.readQuery({ query: ListRestrictedUrls })
    if (newUrl && urls) {
      cache.writeQuery({
        query: ListRestrictedUrls,
        data: {
          urls: [...urls, newUrl]
        }
      })
    }
  }
}))

insertDone((res) => {
  const adminID = auth.getUserId()
  createAdminAction({ userId: adminID, actionId: 11, target: res.data.admin_insert_restricted_url.host })
})

const createUrl = () => {
  const url = prompt('What url would you like to block?')
  console.log('URL', url)
  if (url === null) {
    return
  }
  insertItem({ host: url })
}

onMounted(() => {
  refetch()
})

watch(result, (value) => {
  console.log('VALUE', value)
  if (value?.urls) {
    urlArray.value = value.urls
  }
})
</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="finduser" class="w-5/6">
      <div class="w-full prose prose-slate flex flex-wrap justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0 w-96">Blocked Domains</h2>
        <form v-on:submit.prevent="onSubmit" class="w-1/2 flex md:justify-end sm:justify-start">
          <CustomFilterInput v-model="searchText" class="w-[100%] rounded-sm" />
        </form>
        <div class="btn btn-primary rounded-sm min-h-[2rem] h-[2.3rem]" @click="createUrl">Block New Domain</div>
      </div>
      <div v-if="loading"><p>Loading domains...</p></div>
      <div class="overflow-x-auto mt-12" v-if="!loading">
        <table class="table border border-slate-300 w-full">
          <thead>
            <tr>
              <th class="rounded-sm"></th>
              <th>ID</th>
              <th>Host</th>
              <th class="rounded-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover" v-for="url in filteredArray">
              <th></th>
              <td>{{ url.id }}</td>
              <td>{{ url.host }}</td>
              <td><button class="btn btn-primary btn-sm" @click="deleteItem({ id: url.id })">Unblock</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
