<script setup>
import Login from '@/gql/general/Login.gql'
import { useLazyQuery, provideApolloClient, useMutation } from '@vue/apollo-composable';
import apolloClient from '@/services/apollo';
import { ref, watch } from 'vue';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
const router = useRouter()
// import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql';
import auth from '@/services/auth';

provideApolloClient(apolloClient);

const email = ref(null);
const password = ref(null);

// const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { result, load, loading, error, refetch } = useLazyQuery(
  Login,
  () => ({
    email: email.value,
    password: password.value,
  }),
)

watch(result, value => {
  if (value.token !== undefined) {
    localStorage.setItem('access_token', value.token)
    // const adminID = auth.getUserId()
    // createAdminAction({ userId: adminID, actionId: 15, target: null })
    router.push('/')
  }
})

watch(error, value => {
  if (value) console.error(value)
})
// watch(adminActionError, value => {
//   if (value) alert(value)
// })

const login = () => {
  if (email.value.trim().length > 0 && password.value.trim().length > 0) {
    if (result.value === undefined && !error.value) load()
    else refetch()
  }
}
</script>


<template>
    <div id="loginContainer" class="w-full h-[28rem] flex justify-center">
        <div class="bg-white flex h-full w-[40rem] flex-col p-10">
            <img src="../assets/clouthub-logo.png" class="w-52"/>
            <div class="border-slate-400 border mt-8 p-8 flex flex-col">
                <label for="email">Email</label>
                <input type="text" name="email" v-model="email" class="bg-blue-100 mt-2 w-full border border-slate-400 h-9 rounded-md p-3" />
                <label for="email" class="mt-5">Password</label>
                <input type="password" name="email" v-model="password" class="bg-blue-100 mt-2 w-full border border-slate-400 h-9 rounded-md p-3" />
                <button @click="login" class="bg-blue-500 w-[110px] h-[35px] m-auto mt-10 rounded text-white" @disabled="loading">Login</button>
            </div>
        </div>
    </div>
</template>


