<script setup>
import { ref, watch, computed } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import SetBadgeType from '@/gql/verification/SetBadgeType.gql'

const props = defineProps(['user', 'closeModal', 'refetch'])
const rejectReason = ref(null)

const profileLink = computed(() => props.user && `${import.meta.env.VUE_APP_URL}/#/users/id/${props.user.id}`)

const profilePicture = computed(() => {
  if (props.user && props.user.profilePhotoURL) {
    let pp = props.user.profilePhotoURL
    if (!pp.startsWith('http')) {
      pp = `https://profile-clouthub.s3.amazonaws.com/${props.user.profilePhotoURL}`
    }
    return pp.replace('profile-clouthub.s3.amazonaws.com', 'chprofile.backendcdn.com').replace('profile-clouthub.s3.us-west-1.amazonaws.com', 'chprofile.backendcdn.com')
  }
  return null
})

const verificationImage = computed(() => {
  if (props.user && props.user.verificationUrl) {
    let pp = props.user.verificationUrl
    if (!pp.startsWith('http')) {
      pp = `https://forum-clouthub.s3.amazonaws.com/${props.user.verificationUrl}`
    }
    return pp.replace('forum-clouthub.s3.amazonaws.com', 'chforum.backendcdn.com').replace('forum-clouthub.s3.us-west-1.amazonaws.com', 'chforum.backendcdn.com')
  }
  return null
})

const { mutate: setBadgeType, onDone: setBadgeDone, error: badgeError } = useMutation(SetBadgeType)

const handleSetBadgeType = async (badgeType, verificationStatus) => {
  try {
    setBadgeType({
      id: props.user.id,
      verificationStatus,
      badgeType,
      rejectionReason: rejectReason.value
    })
    if (rejectReason.value) {
      props.closeModal()
    }
  } catch (err) {
    alert(err)
  }
}

setBadgeDone(() => {
  // if (props.refetchUser) {
  //   props.refetchUser()
  // }
  props.refetch()
  rejectReason.value = null
})

watch(badgeError, (value) => {
  alert(badgeError)
})
</script>

<template>
  <div class="modal">
    <div class="modal-box flex flex-col justify-center items-center prose prose-slate rounded-sm md:max-w-4xl">
      <h3 class="font-bold text-lg flex justify-center">Verify {{ props.user.username }}</h3>
      <div class="flex justify-around w-full mb-4">
        <div class="flex flex-col justify-center items-center w-64">
          <h5 class="m-0 p-0">Profile Picture</h5>
          <img :src="profilePicture" v-if="profilePicture" class="m-0 w-[120px]" />
          <h5 class="m-0 p-0 mt-10">Identity Verification</h5>
          <img :src="props.user.verificationUrl" v-if="props.user.verificationUrl" class="m-0 w-[120px]" />
          <div class="border p-0 m-0 w-36">
            <p v-if="!user.verificationUrl" class="font-bold p-0 mt-3">No documentation provided</p>
          </div>
        </div>
        <div class="w-64 flex flex-col justify-center items-center">
          <div><p class="m-2 font-bold">Approve</p></div>
          <label for="verify-modal" class="btn btn-primary btn-sm m-2" @click="handleSetBadgeType('user', 'accepted')">Approve as Verified User</label>
          <label for="verify-modal" class="btn btn-primary btn-sm m-2" @click="handleSetBadgeType('influencer', 'accepted')">Approve as Influencer</label>
          <label for="verify-modal" class="btn btn-primary btn-sm m-2" @click="handleSetBadgeType('founder', 'accepted')">Approve as Founder</label>
          <label class="m-2 mt-10 font-bold">Reject</label>
          <select class="select select-primary select-sm w-full max-w-xs rounded-sm" required v-model="rejectReason">
            <option disabled selected>Choose Reason....</option>
            <option value="no-user-or-id">No Picture of User or ID</option>
            <option value="no-user">No Picture of User</option>
            <option value="no-id">No Picture of ID</option>
            <option value="unclear-id">Unclear ID</option>
            <option value="name-mismatch">Name doesn't match</option>
            <option value="influencer-need-more-info">Influencer Need More Info</option>
          </select>
          <button for="verify-modal" class="btn btn-primary btn-sm mt-4" @click="handleSetBadgeType('none', 'none')" :disabled="!rejectReason">Reject</button>
        </div>
      </div>
      <div class="modal-action flex justify-around w-5/6">
        <label for="verify-modal" class="btn w-24" @click="handleCancel">Cancel</label>
      </div>
    </div>
  </div>
</template>
