<script setup>
import CustomInput from '@/components/CustomInput.vue'
import { ref, watch } from 'vue'
import FindUsers from '@/gql/users/FindUsers.gql'
import CreateUser from '@/gql/users/CreateUser.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import CreateUserModal from '@/components/CreateUserModal.vue'
import auth from '../../services/auth.js'

const searchText = ref('')
const searchBy = ref('username')
const usersArray = ref(null)
const newUser = ref({})

const { mutate: createAdminAction, onDone: adminActionDone, error: adminActionError } = useMutation(CreateAdminAction)

adminActionDone((result) => {
    console.log('ADMIN ACTION DONE', result)
})

const { result, load, loading, error, refetch } = useLazyQuery(FindUsers, () => ({
    filterKey: searchBy.value,
    filterValue: searchText.value
}))

const {
    mutate: createUserMutation,
    onDone: userCreated,
    error: userError
} = useMutation(CreateUser, () => ({
    update: (cache, { data }, { variables }) => {}
}))

const onSubmit = () => {
    console.log(searchText.value, searchBy.value)
    if (searchText.value !== '' && searchBy.value) {
        if (result.value === undefined && !error.value) {
            load()
        } else {
            refetch()
        }
    }
}

userCreated((result) => {
    const adminID = auth.getUserId()
    if (result) {
        newUser.value = {}
        alert('User created successfully')
        createAdminAction({ userId: adminID, actionId: 1, target: result.data.user.id })
    }
})

const createUser = async () => {
    newUser.value.email = newUser.value.email.toLowerCase().trim()
    try {
        console.log('new user values before create', newUser.value)
        if (!newUser.value.username || !newUser.value.email) {
            alert('Missing one or more required parameters')
            return
        }
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(newUser.value.email)) {
            alert('Please enter a valid email address')
            return
        }
        if (!newUser.value.username.startsWith('@')) {
            newUser.value.username = `@${newUser.value.username}`
        }
        if (!newUser.value.displayname) {
            newUser.value.displayname = newUser.value.username.replace('@', '')
        }
        createUserMutation(newUser.value)
    } catch (err) {
        alert(err)
    }
}

watch(result, (value) => {
    if (value?.users) {
        usersArray.value = value?.users
    }
})

watch(userError, (value) => {
    if (userError) {
        alert(value)
    }
})

watch(adminActionError, (value) => {
    if (value) {
        alert(value)
    }
})

</script>

<template>
    <div id="page-container" class="w-full h-full flex justify-center mt-11">
        <div id="finduser" class="w-5/6 h-full">
            <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
                <h2 class="justify-self-center mb-0">Find Users</h2>
                <label class="btn btn-primary rounded-sm" for="create-user-modal">Create User</label>
                <input type="checkbox" id="create-user-modal" class="modal-toggle" />
                <!-- create User Modal-->
                <CreateUserModal
                    v-model:email="newUser.email"
                    v-model:username="newUser.username"
                    v-model:displayname="newUser.displayname"
                    v-model:firstname="newUser.firstname"
                    v-model:lastname="newUser.lastname"
                    v-model:password="newUser.password"
                    :createUser="createUser"
                />
                <!-- end create User Modal-->
            </div>
            <form v-on:submit.prevent="onSubmit" class="mt-10 flex flex-col sm:flex-row justify-between items-center">
                <select class="select select-primary md:w-full max-w-xs rounded-sm mb-6 sm:mb-0" v-model="searchBy">
                    <!-- <option disabled selected>Search by...</option> -->
                    <option value="username">Search by Username</option>
                    <option value="email">Search by Email</option>
                    <option value="phoneNo">Search by Phone Number</option>
                    <option value="displayname">Search by Display Name</option>
                    <option value="lastname">Search by Last Name</option>
                    <option value="firstname">Search by First Name</option>
                </select>
                <CustomInput v-model="searchText" class="sm:w-[50%] xl:w-[60%]" />
                <!-- <input class="hidden"/> -->
                <!-- <button class="btn btn-primary btn-sm" type="submit">Search</button> -->
            </form>
            <div class="overflow-x-auto mt-12" v-if="usersArray">
                <table class="table w-full">
                    <!-- head -->
                    <thead>
                        <tr>
                            <th class="rounded-sm"></th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Phone #</th>
                            <th>Displayname</th>
                            <th>Firstname</th>
                            <th class="rounded-sm">Lastname</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in usersArray" class="hover:bg-slate-200">
                            <th class="bg-transparent rounded-sm"></th>
                            <router-link :to="`/user/${user.id}`" class="bg-transparent">
                                <td class="bg-transparent">
                                    <p class="font-bold hover:scale-125 ease-in duration-100">{{ user.username }}</p>
                                </td>
                            </router-link>
                            <td class="bg-transparent">{{ user.email }}</td>
                            <td class="bg-transparent">{{ user.phoneNo }}</td>
                            <td class="bg-transparent">{{ user.displayname }}</td>
                            <td class="bg-transparent">{{ user.firstname }}</td>
                            <td class="bg-transparent rounded-sm">{{ user.lastname }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
