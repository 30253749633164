<script setup>
import { ref } from 'vue'

const menuGray = ref(false)

const menuColorChange = (event) => {
    if (event.type === 'mouseover') {
        menuGray.value = true
    } else {
        menuGray.value = false
    }
}

const handleClick = () => {
    const el = document.activeElement;
    if (el) {
        el?.blur()
    }
}
</script>

<template>
    <!-- responsive dropdown menu for small screens -->
    <div class="dropdown">
        <label tabindex="0" class="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
        </label>
        <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-md w-52" v-bind:class="menuGray ? 'bg-gray-200' : ''">
            <li tabindex="0">
                <a class="justify-between !rounded-sm" v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()">Users</a>
                <ul class="p-2 bg-white drop-shadow-md">
                    <router-link to="/findusers"
                        ><li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10 !rounded-sm">Find Users</a></li></router-link
                    >
                    <router-link to="/suspendedusers">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10 !rounded-sm">Suspended Users</a></li>
                    </router-link>
                    <router-link to="/verifyusers">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10 !rounded-sm">Verify Users</a></li>
                    </router-link>
                    <router-link to="/recentusers">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10 !rounded-sm">Recently Joined</a></li>
                    </router-link>
                </ul>
            </li>
            <li tabindex="0">
                <a class="justify-between !rounded-sm" v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()">Blacklists</a>
                <ul class="p-2 bg-white drop-shadow-md">
                    <router-link to="/blockeddomains">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10">Blocked Domains</a></li>
                    </router-link>
                    <router-link to="/blockedemaildomains">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10">Blocked Emails</a></li>
                    </router-link>
                    <router-link to="/blockedwords">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10">Blocked Words</a></li>
                    </router-link>
                </ul>
            </li>
            <li tabindex="0">
                <a class="justify-between !rounded-sm" v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()">Reports</a>
                <ul class="p-2 bg-white drop-shadow-md">
                    <router-link to="/reportedusers">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10">Reported Users</a></li>
                    </router-link>
                </ul>
            </li>
            <li tabindex="0">
                <a class="justify-between !rounded-sm" v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()">Channels</a>
                <ul class="p-2 bg-white drop-shadow-md">
                    <router-link to="/livechannels">
                        <li v-on:mouseover="menuColorChange($event)" v-on:mouseout="menuColorChange($event)" v-on:click="handleClick()"><a class="h-10">Live Channels</a></li>
                    </router-link>
                </ul>
            </li>
            <router-link to="/moderation">
                <li><a class="!rounded-sm" v-on:click="handleClick()">Moderation</a></li>
            </router-link>
            <router-link to="/notifications">
                <li><a class="!rounded-sm" v-on:click="handleClick()">Notifications</a></li>
            </router-link>
            <li><a class="!rounded-sm" v-on:click="handleClick()">Meetings</a></li>
        </ul>
    </div>
    <!-- end responsive dropdown -->
</template>
