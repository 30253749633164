<script setup>
import { ref, computed, watch, onBeforeMount } from 'vue'
import EditPaymentsModal from '../modals/EditPaymentsModal.vue'
import { useMutation } from '@vue/apollo-composable'
import SetUserPaymentSettings from '@/gql/users/SetUserPaymentSettings.gql'
import CreateAdminAction from '@/gql/admin/CreateAdminAction.gql'
import auth from '@/services/auth'

const props = defineProps({
  user: Object,
  refetchUser: Function
})

onBeforeMount(() => {
  if (props.user?.enablePayments) {
    paymentParams.value.enablePayments = props.user.enablePayments
  }
  if (props.user?.paymentName) {
    paymentParams.value.paymentName = props.user.paymentName
  }
  if (props.user?.paymentAddress) {
    paymentParams.value.paymentAddress = props.user.paymentAddress
  }
})

const paymentParams = ref({
  enablePayments: false,
  paymentName: '',
  paymentAddress: ''
})

const { mutate: createAdminAction, error: adminActionError } = useMutation(CreateAdminAction)
const { mutate: setPayment, onDone: setPaymentDone, error: setPaymentError } = useMutation(SetUserPaymentSettings)

const handleUpdate = () => {
  try {
    setPayment({
      userId: props.user.id,
      enablePayments: paymentParams.value.enablePayments,
      paymentName: paymentParams.value.paymentName,
      paymentAddress: paymentParams.value.paymentAddress
    })
  } catch (err) {
    alert(err)
  }
}

const handleCancel = () => {
  if (props.user?.enablePayments) {
    paymentParams.value.enablePayments = props.user.enablePayments
  }
  if (props.user?.paymentName) {
    paymentParams.value.paymentName = props.user.paymentName
  }
  if (props.user?.paymentAddress) {
    paymentParams.value.paymentAddress = props.user.paymentAddress
  }
}

setPaymentDone(async (res) => {
  await props.refetchUser()
  if (paymentParams.value.enablePayments === true) {
    const adminID = auth.getUserId()
    createAdminAction({ userId: adminID, actionId: 7, target: props.user.id })
  } else if (paymentParams.value.enablePayments === false) {
    const adminID = auth.getUserId()
    createAdminAction({ userId: adminID, actionId: 31, target: props.user.id })
  }
})

watch(setPaymentError, (value) => {
  alert(value)
})
</script>

<template>
  <!-- Payments CARD -->
  <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="profile-card">
    <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
      <h3>Payments</h3>
    </div>
    <div class="card-body items-center text-center">
      <table class="table table-compact w-full" v-if="props.user">
        <tr>
          <td><p class="font-bold">Enabled</p></td>
          <td>{{ paymentParams.enablePayments ? 'Enabled' : 'Disabled' }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Name</p></td>
          <td>{{ paymentParams.paymentName }}</td>
        </tr>
        <tr>
          <td><p class="font-bold">Address</p></td>
          <td>{{ paymentParams.paymentAddress }}</td>
        </tr>
      </table>
      <div class="card-actions w-full">
        <label class="btn btn-primary w-full rounded-sm" for="edit-payments-modal">Edit Payments</label>
        <input type="checkbox" class="modal-toggle" id="edit-payments-modal" />
        <EditPaymentsModal
          v-model:enabled="paymentParams.enablePayments"
          v-model:name="paymentParams.paymentName"
          v-model:address="paymentParams.paymentAddress"
          :updatePayments="handleUpdate"
          :handleCancel="handleCancel"
        />
      </div>
    </div>
  </div>
  <!-- End Payments Card-->
</template>
