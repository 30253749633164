query ListUserSessions($userId:String!){
  sessions: admin_list_user_sessions(userId:$userId){
    createdAt
    os
    device
    appVersion
    ip
    browser
    userAgent
  }
}