mutation UpdateUser(
  $id:String!,
  $firstname:String,
  $lastname:String,
  $username:String,
  $email:String,
  $displayname:String,
  $phoneNo:String,
) {
  admin_update_user(
    id:$id,
    firstname:$firstname,
    lastname:$lastname,
    username:$username,
    email:$email,
    displayname:$displayname,
    phoneNo:$phoneNo,
  ) {
    id
  }
}
