query {
    stats: admin_get_dash_stats {
        user_count,
        new_user_count,
        suspended_user_count,
        most_followers {
            id,
            firstname,
            lastname,
            username
        },
        most_friends {
            id,
            firstname,
            lastname,
            username
        },
        most_posts {
            id,
            firstname,
            lastname,
            username
        }
    }
}