<script setup>
import ListPostReports from '@/gql/reports/ListPostReports.gql';
import ClearPostReports from '@/gql/reports/ClearPostReports.gql'
import DeletePost from '@/gql/reports/DeletePost.gql'
import { useQuery, useMutation } from "@vue/apollo-composable";
import { watch, ref, onMounted } from 'vue'
import {formatDate} from '@/services/utilities.js'

const reports = ref(null)

const { result: postReports, loading, error, refetch } = useQuery(ListPostReports)
const { mutate: clearPosts, error: clearError, onDone: clearDone } = useMutation(ClearPostReports)
const { mutate: deletePost, error: deleteError, onDone: deleteDone } = useMutation(DeletePost)


const handleDeletePost = (id) => {
  // DeletePost(id)
  // ClearPostReports(id)
}

watch(postReports, (value) => {
  console.log('postReports', value)
  reports.value = value.reports
})

onMounted(() => {
  refetch();
})

clearDone(() => {
  refetch()
})

deleteDone(() => {
  refetch()
})

watch(clearError, (value) => {
  if (value) {
    alert('Error: ' + value)
  }
})
watch(deleteError, (value) => {
  if (value) {
    alert('Error: ' + value)
  }
})


</script>

<template>
  <div id="page-container" class="w-full h-full flex justify-center mt-11">
    <div id="reportedusers" class="w-5/6">
      <div class="w-full prose prose-slate flex justify-between max-w-full items-center h-20">
        <h2 class="justify-self-center mb-0">Reported Posts</h2>
        <!-- <div class="btn btn-primary rounded-sm">Wizard Mode</div> -->
      </div>
      <div class="mt-10">
        <div class="overflow-x-auto" v-if="reports">
          <table class="table table-compact border border-slate-300 w-full">
            <thead>
              <tr>
                <th class="rounded-sm"></th>
                <th>ID</th>
                <th>Total Reports</th>
                <th>Latest Report</th>
                <th class="rounded-sm">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in reports">
                <th></th>
                <td>{{ report.id }}</td>
                <td>{{ report.total_reports }}</td>
                <td>{{ formatDate(report.latest_report) }}</td>
                <td>
                  <button class="btn btn-primary btn-sm" @click="ClearPostReports({ id: report.id })">Clear Reports</button>
                  <button class="btn btn-primary btn-sm ml-4" @click="handleDeletePost({ id: report.id })">Delete Post</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
