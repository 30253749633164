<script setup>
import { computed, ref, watch, toRefs } from 'vue'
import SetBadgeType from '@/gql/verification/SetBadgeType.gql'
import { useMutation } from '@vue/apollo-composable'
import UserVerificationModal from '@/components/modals/UserVerificationModal.vue'

const props = defineProps({
    user: Object,
    refetchUser: Function
})

const closeModal = () => {
    document.getElementById('verify-modal').checked = false;
}
</script>

<template>
    <div class="card w-96 bg-base-100 shadow-xl m-7 rounded-md border-2 border-neutral-100" id="channel-card">
        <div class="py-2 pl-4 bg-slate-200 flex items-center rounded-sm prose prose-slate">
            <h3>Verification Status</h3>
        </div>
        <div class="card-body items-center text-center">
            <div class="mb-4">
                <p>{{ props.user.badgeType || 'None' }}</p>
            </div>
            <div class="card-actions w-full">
                <label class="btn btn-primary w-full rounded-sm" for="verify-modal">Verify</label>
                <input type="checkbox" id="verify-modal" class="modal-toggle" />
                <UserVerificationModal :user="props.user" :closeModal = "closeModal" :refetch="props.refetchUser" />
            </div>
        </div>
    </div>
</template>
